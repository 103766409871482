import React from 'react';
import styled from 'styled-components';

const FabricantesListaMainMenu = ({ fabricantes }) => {
  return (
    <ListaDeFabricantes>
      <a>Marcas</a>
    <ul>
      {fabricantes.map(
        (fabricante)=><li key={fabricante.id}><a href={fabricante.url}>{fabricante.nombre}</a></li>
        )}
    </ul>
        </ListaDeFabricantes>
  );
};

const ListaDeFabricantes = styled.li`
font-weight: bold;
break-inside: avoid-column;
display: block;
@media screen and (max-width: ${(props) => props.theme.footerMobileVisibilityBreakPoint}) {
  display: none;
}
> ul > li {
  > a {
        font-weight: normal;
        font-size: 14px;
        padding: 5px 0;
        display: block;
        color: black;
        text-decoration: none;
        > svg{
          display: none;
        }
        &:hover {
          text-decoration: none;
          text-decoration: underline;
        }
        @media screen and (max-width: ${(props) => props.theme.footerMobileVisibilityBreakPoint}) {
          padding: 10px;
          background-color: ${(props) => props.theme.secundarioClaro};
          > svg{
          display: inline-block;
          }
        }
      }
    }
`;


export default FabricantesListaMainMenu;
