var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
var Loading = function (_a) {
    var _b = _a.elemento, elemento = _b === void 0 ? "" : _b, _c = _a.explicitHeights, explicitHeights = _c === void 0 ? [0, 0, 0] : _c;
    return (React.createElement(StyledLoader, { elemento: elemento, explicitHeights: explicitHeights },
        React.createElement(FontAwesomeIcon, { icon: faSpinner, spin: true, size: "xl" })));
};
var StyledLoader = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  clear: both;\n  /*alto para desktop*/\n  height: ", ";\n  @media screen and (max-width: ", ") {\n    height: ", ";\n  }\n  @media screen and (max-width: ", ") {\n    height: ", ";\n  }\n  text-align: center;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  overflow: hidden;\n  border-radius: ", ";\n  color: ", ";\n  ", "\n"], ["\n  width: 100%;\n  clear: both;\n  /*alto para desktop*/\n  height: ", ";\n  @media screen and (max-width: ", ") {\n    height: ", ";\n  }\n  @media screen and (max-width: ", ") {\n    height: ", ";\n  }\n  text-align: center;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  overflow: hidden;\n  border-radius: ", ";\n  color: ", ";\n  ", "\n"])), function (props) { return props.explicitHeights[2] + "px"; }, function (props) { return props.theme.mediumBreakPoint; }, function (props) { return props.explicitHeights[1] + "px"; }, function (props) { return props.theme.smallMobileBreakPoint; }, function (props) { return props.explicitHeights[0] + "px"; }, function (props) { return props.theme.smallBorderRadius; }, function (_a) {
    var elemento = _a.elemento, theme = _a.theme;
    switch (elemento) {
        case "landing-hot-sale":
            return theme.hotSaleColor;
        default:
            return theme.primarioHover;
    }
}, function (_a) {
    var elemento = _a.elemento;
    return elemento === "formularios" && "position: absolute; top: 0px; opacity: 0.3;";
});
export default Loading;
var templateObject_1;
