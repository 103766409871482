import React, { createElement, Fragment, useEffect, useRef } from 'react';
import { render } from 'react-dom';
import Styled from "styled-components";
import { autocomplete } from '@algolia/autocomplete-js';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import { createQuerySuggestionsPlugin } from "@algolia/autocomplete-plugin-query-suggestions";
import { getAlgoliaResults } from "@algolia/autocomplete-js";
import algoliasearch from "algoliasearch";


const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
  key: 'RECENT_SEARCH',
  limit: 5,
  transformSource({ source }) {
    return {
      ...source,
      onSelect({ setIsOpen, state }) {
        setIsOpen(false);//Sirve para dejar el panel abierto.
        window.location.href = `/buscar/?query=${state.query}`
      },
      templates: {
        ...source.templates,
        header() {
          return (
            <>
              <span className="aa-SourceHeaderTitle">Tus búsquedas recientes</span>
              <div className="aa-SourceHeaderLine" />
            </>
          )
        },
      },
    };
  },
});


export function Autocomplete(props) {
  const searchClient = algoliasearch(
    props.configuraciones && props.configuraciones.algoliaId,
    props.configuraciones && props.configuraciones.algoliaKey
  );

  const hotSaleActivo = (datosHotSale) => {
    return new Date(datosHotSale.inicio).getTime() <= new Date().getTime() && new Date(datosHotSale.final).getTime() >= new Date().getTime()
  }

  const SuguerenciasDeBusqueda = ({ item, components }) => {
    return (
      <a href={item.product_url} className="aa-ItemLink">
        <div className="aa-ItemContent">
          <div className="aa-ItemIcon aa-ItemIcon--picture aa-ItemIcon--alignTop">
            <img src={item.photo_url} alt={item.name} width="60" height="60" />
          </div>
  
          <div className="aa-ItemContentBody">
            <div className="aa-ItemContentTitle">
              <components.Highlight hit={item} attribute="product_name" />
            </div>
            <div className="aa-ItemContentDescription">
              De <strong>{item.producer_name.split("||")[1]}</strong> en{" "}
              <strong>{item.category}</strong>
            </div>
            <div className="aa-ItemCurrencyAndPrice">
              {item.datosHotSale !== null && hotSaleActivo(item.datosHotSale) ? (
                <>
                  <HitMarketPrice> {`${item.currency} ${item.product_price_int.toLocaleString()}`}</HitMarketPrice>
                  <ArticuloPrecioHotSale>
                    {`${item.currency} ${item.datosHotSale.precio_final_formateado}`}
                  </ArticuloPrecioHotSale>
                </>
              ) : (
                <strong>
                  {`${item.currency} ${item.product_price_int.toLocaleString()}`}
                </strong>
              )}
            </div>
          </div>
        </div>
  
        <div className="aa-ItemActions">
          <button
            className="aa-ItemActionButton aa-DesktopOnly aa-ActiveOnly"
            type="button"
            title="Select"
            style={{ pointerEvents: "none" }}
          >
            <svg viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
              <path d="M18.984 6.984h2.016v6h-15.188l3.609 3.609-1.406 1.406-6-6 6-6 1.406 1.406-3.609 3.609h13.172v-4.031z" />
            </svg>
          </button>
        </div>
      </a>
    );
  };

  const querySuggestionsPlugin = createQuerySuggestionsPlugin({
    searchClient,
    indexName: props.configuraciones && props.configuraciones.suggestions, //uy_products_query_suggestions_tmp
    getSearchParams({ state }) {
      //Solo se traen resultados si se escriben datos en buscador.
      return { hitsPerPage: state.query === null || state.query.length < 3 ? 0 : 5};
    },
    transformSource({ source }) {
      return {
        ...source,
        onSelect({ setIsOpen, state }) {
          setIsOpen(false); //Sirve para dejar el panel abierto.
          window.location.href = `/buscar/?query=${state.query}`;
        },
        templates: {
          ...source.templates,
          header() {
            return (
              <>
                <span className="aa-SourceHeaderTitle">
                  Búsquedas populares
                </span>
                <div className="aa-SourceHeaderLine" />
              </>
            );
          },
          item({ item, components }) {
            return (
              <div className="aa-ItemWrapper">
                <div className="aa-ItemContent">
                  <div className="aa-ItemIcon aa-ItemIcon--noBorder">
                  {!item.__autocomplete_qsCategory && (
                    <svg
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      fill="currentColor"
                    >
                      <path d="M16.041 15.856c-0.034 0.026-0.067 0.055-0.099 0.087s-0.060 0.064-0.087 0.099c-1.258 1.213-2.969 1.958-4.855 1.958-1.933 0-3.682-0.782-4.95-2.050s-2.050-3.017-2.050-4.95 0.782-3.682 2.050-4.95 3.017-2.050 4.95-2.050 3.682 0.782 4.95 2.050 2.050 3.017 2.050 4.95c0 1.886-0.745 3.597-1.959 4.856zM21.707 20.293l-3.675-3.675c1.231-1.54 1.968-3.493 1.968-5.618 0-2.485-1.008-4.736-2.636-6.364s-3.879-2.636-6.364-2.636-4.736 1.008-6.364 2.636-2.636 3.879-2.636 6.364 1.008 4.736 2.636 6.364 3.879 2.636 6.364 2.636c2.125 0 4.078-0.737 5.618-1.968l3.675 3.675c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414z" />
                    </svg>
                    )}
                  </div>
                  <div className="aa-ItemContentBody">
                    <div className="aa-ItemContentTitle">
                      {!item.__autocomplete_qsCategory && (
                        <components.ReverseHighlight
                          hit={item}
                          attribute="query"
                        />
                      )}
                      {item.__autocomplete_qsCategory && (
                        <span className="aa-ItemContentSubtitle aa-ItemContentSubtitle--standalone">
                          <span className="aa-ItemContentSubtitleIcon" /> en{" "}
                          <span className="aa-ItemContentSubtitleCategory">
                            {item.__autocomplete_qsCategory}
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
  
                <div className="aa-ItemActions">
                  <button
                    className="aa-ItemActionButton"
                    title={`Completar búsqueda con "${item.query}"`}
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      onTapAhead(item);
                    }}
                  >
                    <svg
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      fill="currentColor"
                    >
                      <path d="M8 17v-7.586l8.293 8.293c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-8.293-8.293h7.586c0.552 0 1-0.448 1-1s-0.448-1-1-1h-10c-0.552 0-1 0.448-1 1v10c0 0.552 0.448 1 1 1s1-0.448 1-1z" />
                    </svg>
                  </button>
                </div>
              </div>
            );
          },
        },
      };
    },
    categoryAttribute: [
      props.configuraciones && props.configuraciones.suggestions_principal, //
      "facets",
      "exact_matches",
      "sub_category",
    ],
    itemsWithCategories: 1,
    categoriesPerItem: 2,
  });

  const containerRef = useRef(null);


  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const search = autocomplete({
      container: containerRef.current,
      openOnFocus: true,
      getSources({ query }) {
        if ((!query || query.length < 3)|| props.autocompleteActivo == 0) {
          return [];
        }
        return [
          {
            sourceId: "products",
            getItemUrl({ item }) {
              return item.product_url;
            },
            getItems() {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: props.configuraciones && props.configuraciones.algoliaIndice,
                    query,
                    params: {
                      facetFilters: ["product_enabled:1"],
                    },
                  },
                ],
              });
            },
            templates: {
              item({ item, components }) {
                return (
                  <SuguerenciasDeBusqueda item={item} components={components} />
                );
              },
              header() {
                return (
                  <>
                    <span className="aa-SourceHeaderTitle">Productos</span>
                    <div className="aa-SourceHeaderLine" />
                  </>
                );
              },
              noResults() {
                return "Sin resultados para esta búsqueda.";
              },
            },
          },
          {
            sourceId: "categories",
            getItemUrl({ item }) {
              return item.url;
            },
            getItems() {
              if (!query || props.autocompleteActivo == 0) {
                //Si no hay query no hace busqueda.
                return [];
              }
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName:
                      props.configuraciones && props.configuraciones.algoliaIndiceCategorias,
                    query,
                    params: {
                      facetFilters: ["estado:1"],
                    },
                  },
                ],
              });
            },
            templates: {
              item({ item, components, state }) {
                if (!state.query) {
                  return null;
                }
        
                return (
                  <a className="aa-categoryItem" href={item.url}>
                    <components.Highlight hit={item} attribute="categ_arbol" />
                  </a>
                );
              },
              header({ state }) {
                if (!state.query) {
                  return null;
                }
                return (
                  <>
                    <span className="aa-SourceHeaderTitle">Categorías</span>
                    <div className="aa-SourceHeaderLine" />
                  </>
                );
              },
              /*noResults({ state }) {
                  if(!state.query){
                    return null;
                  }
                  return 'Sin resultados para esta búsqueda.';
                },*/
            },
          },
        ]
      },
      navigator: {
        navigate({ itemUrl }) {
          window.location.assign(itemUrl);
        },
        navigateNewTab({ itemUrl }) {
          const windowReference = window.open(itemUrl, '_blank', 'noopener');
    
          if (windowReference) {
            windowReference.focus();
          }
        },
        navigateNewWindow({ itemUrl }) {
          window.open(itemUrl, '_blank', 'noopener');
        },
      },
      translations:{
        clearButtonTitle: 'Limpiar', // defaults to 'Clear'
        detachedCancelButtonText: 'Cancelar', // defaults to 'Cancel'
        submitButtonTitle: 'Buscar', // defaults to 'Submit'
      },
      plugins: props.autocompleteActivo == 1 ? [recentSearchesPlugin, querySuggestionsPlugin] : [],
      detachedMediaQuery: 'none',
      placeholder: 'Buscar...',
      initialState: {
        query: props.currentRefinement? props.currentRefinement : null,
      },
      onSubmit({ event, state }) {
        const query = state.query? state.query : "";
        event.preventDefault();
        if(props.redireccionBusqueda){
          if(props.autocompleteActivo == 0){
            props.refine(query);
          }else if (props.autocompleteActivo == 1){
            window.location.href = "/buscar/?query="+query
          }
        }else{
          props.setSearchState({
            query: decodeURIComponent(query),
            page: 1,
            menu: {},
            refinementList: {},
          });
        }
      },
      //onSelect:
      //panelContainer: '.ContenedorSugerencias',
      classNames: {
        input: 'q',
        submitButton: 'submitButton',
      },
      renderer: { createElement, Fragment },
      render({ children }, root) {
        render(children, root);
      },
    });

    return () => {
      search.destroy();
    };
  }, [props]);


  return props.configuraciones && props.configuraciones.algoliaIndice ? (
    <div ref={containerRef} />
      ) : (
        <>
        </>
      );
}

const HitMarketPrice = Styled.span`
    text-decoration: line-through;
    color: #ccc;
    font-size:14px;
    font-weight: bold;
`;

const ArticuloPrecioHotSale = Styled.p`
  font-size: 1.3;
  font-weight: bold;
  margin: 0;
  padding: 0;
  color: red;
  span {
    display: inline-block;
    font-size: 1.3;
  }
`;