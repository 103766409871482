var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
var TituloSeccion = function (_a) {
    var _b = _a.icono, icono = _b === void 0 ? null : _b, _c = _a.iconoSize, iconoSize = _c === void 0 ? "1x" : _c, titulo = _a.titulo, vinculo = _a.vinculo, heroTitle = _a.heroTitle, className = _a.className, contextColor = _a.contextColor;
    return (React.createElement(ContainerTitle, { heroTitle: heroTitle, className: className, contextColor: contextColor },
        icono && React.createElement(FontAwesomeIcon, { icon: icono, size: iconoSize }),
        React.createElement(Titulo, { contextColor: contextColor, heroTitle: heroTitle }, titulo),
        vinculo && React.createElement(Vinculo, { contextColor: contextColor, href: vinculo }, "Ver m\u00E1s")));
};
var ContainerTitle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: ", ";\n  margin: 0 0 10px 0;\n  svg{\n    margin-right: 5px;\n  }\n  border-bottom: ", ";\n"], ["\n  color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: ", ";\n  margin: 0 0 10px 0;\n  svg{\n    margin-right: 5px;\n  }\n  border-bottom: ", ";\n"])), function (props) { return props.contextColor; }, function (props) { return props.theme.titulosDeSeccionesJustifyContent; }, function (props) {
    return props.heroTitle ? props.theme.titulosDeSeccionesBorderBottom : "none";
});
var Titulo = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: inherit;\n  padding: 0;\n  flex: 1;\n  color: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  font-size: ", ";\n  text-transform: ", ";\n  @media screen and (max-width: 919px) {\n    font-size: ", ";\n  }\n"], ["\n  color: inherit;\n  padding: 0;\n  flex: 1;\n  color: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  font-size: ", ";\n  text-transform: ", ";\n  @media screen and (max-width: 919px) {\n    font-size: ", ";\n  }\n"])), function (props) { return props.contextColor ? props.contextColor : props.theme.primarioHover; }, function (props) { return props.theme.titulosDeSeccionesFont; }, function (props) { return props.theme.titulosDeSeccionesFontWheigth; }, function (props) { return props.theme.titulosDeSeccionesFontSize; }, function (props) {
    return props.heroTitle ? props.theme.titulosDeSeccionesTextTransform : "none";
}, function (props) { return props.theme.titulosDeSeccionesFontSizeMobile; });
var Vinculo = styled.a(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: ", ";\n  color: ", ";\n  border-radius: ", ";\n  padding: 10px;\n  font-size: 0.9rem;\n  font-weight: bold;\n  text-decoration: none;\n  margin-left: ", ";\n  &:hover {\n    color: white;\n    background-color: ", ";\n  }\n"], ["\n  font-family: ", ";\n  color: ", ";\n  border-radius: ", ";\n  padding: 10px;\n  font-size: 0.9rem;\n  font-weight: bold;\n  text-decoration: none;\n  margin-left: ", ";\n  &:hover {\n    color: white;\n    background-color: ", ";\n  }\n"])), function (props) { return props.theme.bodyFonts; }, function (props) { return props.contextColor ? props.contextColor : props.theme.primarioHover; }, function (props) { return props.theme.generalBorderRadius; }, function (props) { return props.theme.separacion; }, function (props) { return props.contextColor ? props.contextColor : props.theme.primarioHover; });
export default TituloSeccion;
var templateObject_1, templateObject_2, templateObject_3;
