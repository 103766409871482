const EnviosDisponibles = {
  // datos para tarjetas de productos --tendencias--
  getEnviosDisponibles: () => {
    try {
      const response = document.getElementById("envios_disponibles").value;
      const convertResponse = JSON.parse(response)
      return convertResponse;
    } catch (err) {
      const error = "ERROR_GET_ENVIOS_DISPONIBLES_SERVICE";
      return error;
    }
  },
};
export default EnviosDisponibles;
