import { createSlice } from "@reduxjs/toolkit";
var initialState = {
    isOpen: false,
    type: null,
    data: "",
    titulo: null,
    link: null,
};
var modalSlice = createSlice({
    name: "modal",
    initialState: initialState,
    reducers: {
        openModal: function (state, action) {
            state.isOpen = true;
            state.type = action.payload.type;
            if (action.payload.data)
                state.data = action.payload.data;
            if (action.payload.titulo)
                state.titulo = action.payload.titulo;
            if (action.payload.link)
                state.link = action.payload.link;
        },
        closeModal: function (state) {
            state.isOpen = false;
            state.type = null;
            if (state.data)
                state.data = "";
            if (state.titulo)
                state.titulo = "";
            if (state.link)
                state.link = "";
        },
    },
});
export default modalSlice;
