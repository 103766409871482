import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckDouble,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";

import NotificacionesLista from './notificaciones/NotificacionesLista';

const NotificacionesFullList = ({
  patchearNotificacion,
  notificaciones,
  setVisibilidadDetalleNotificaciones,
  uiContext
}) => {
  return notificaciones ? (
    <ListaNotificacionesFondo
      onClick={() => setVisibilidadDetalleNotificaciones(false)}
    >
      <ListaNotificacionesWrapper>
        <BotonCerrar onClick={() => setVisibilidadDetalleNotificaciones(false)}>
          <FontAwesomeIcon size={"2x"} icon={faTimes} />
        </BotonCerrar>
        {notificaciones && notificaciones.lista && notificaciones.lista.length ? (
          <ListaNotificaciones
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <header>
              <h3>Tenés {notificaciones && notificaciones.lista && notificaciones.lista.length} notificaciones.</h3>
            </header>
            <main>
              <NotificacionesLista uiContext={'detalle'}  patchearNotificacion={patchearNotificacion} notificaciones={notificaciones}/>
            </main>
            <footer>
              <button
                onClick={() => {
                  patchearNotificacion('detalle',"marcarNotificacionesLeidas")
                }
                }
                className="boton-opciones-lista-notificaciones"
              >
                Marcar todas como leidas
              </button>
              <button
                onClick={() =>
                  patchearNotificacion('detalle',"borrarNotificaciones")
                }
                className="boton-opciones-lista-notificaciones"
              >
                Borrar todas
              </button>
            </footer>
          </ListaNotificaciones>
        ) : (
          <ListaNotificaciones>
            <AvisoNotificacionesSinLeer>
              <FontAwesomeIcon size={"2x"} icon={faCheckDouble} />
              No tenés notificaciones
            </AvisoNotificacionesSinLeer>
          </ListaNotificaciones>
        )}
      </ListaNotificacionesWrapper>
    </ListaNotificacionesFondo>
  ) : null;
};
const ListaNotificacionesFondo = styled.section`
  background-color: rgba(12, 48, 113, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;
  right: 0;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 2;
`;

const ListaNotificacionesWrapper = styled.section`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 10px;
  bottom: 60px;
`;
const BotonCerrar = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0;
  border-radius: 30px;
  border: 1px solid #014ECB;
  background-color: white;
  > svg {
    color: #014ECB;
  }
  &:hover {
    > svg {
      color: white;
    }
    background-color: #014ECB;
  }
`;

const ListaNotificaciones = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 25px -5px rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  margin: 20px;
  > header {
    border-bottom: 1px solid #014ECB;
    padding-bottom: 10px;
    > h3,
    > h4 {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
    }
    > h3 {
      font-size: 14px;
      padding: 10px 0 0 0;
    }
    > h4 {
      font-size: 12px;
      padding: 0;
    }
  }
  > main {
    flex: 1;
    display: flex;
    overflow: hidden;

  }
  > footer {
    border-top: 1px solid #014ECB;
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0;
    padding: 0 0 5px 0;
    button.boton-opciones-lista-notificaciones {
      background-color: white;
      font-size: 14px;
      line-height: 15px;
      padding: 10px 20px;
      margin: 5px;
      color: #004880;
      border: 1px solid white;
      border-radius: 20px;
      text-decoration: none;
      &:hover {
        border-color: #004880;
      }
    }
  }
`;
const AvisoNotificacionesSinLeer = styled.section`
  color: #014ECB;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > svg {
    padding: 20px;
  }
`;

export default NotificacionesFullList;
