import { usuarioToken, usuarioId } from "../data/usuario";
const apiToken = "Bearer " + process.env.API_TOKEN;
const headerData = {
  Usuarioid: usuarioId,
  Usuariotoken: usuarioToken,
  Authorization: apiToken,
};

const getCookie = (name) => {
  const value = document.cookie;
  const parts = value.split(`${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};
const rcuid = getCookie("rcuid");

const Recomendados = {
  //get recomendados para el usuario
  getproductosRecomendadosUsuario: async () => {
    try {
      const response = await fetch(
        `/index.php?ctrl=index&act=soloParaVos&rcuid=${rcuid}`,
        {
          method: "GET",
          headers: headerData,
        }
      );
      const recomendadoResponse = response.json();
      return recomendadoResponse;
    } catch (err) {
      const error = "ERROR_GET_RECOMENDADOS_USUARIOS";
      return error;
    }
  },
};
export default Recomendados;
