import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Notificaciones from "../services/notificaciones";

//## fetch cantidad de notificaciones ##//
export const fetchCantidadDeNotificaciones = createAsyncThunk(
  "fetchCantidadDeNotificaciones/index",
  async (data) => {   
    try {
      const response = await Notificaciones.fetchCantidadDeNotificaciones(data);
      return response;
    } catch (err) {
      const error_cantidad_de_notificaciones =
        "ERROR_FETCH_CANTIDAD_DE_NOTIFICACIONES";
      console.error(error_cantidad_de_notificaciones, err);
    }
  }
);
//## fetch todas las notificaciones sin leer ##/
export const fetchTodasLasNotificacionesSinLeer = createAsyncThunk(
  "fetchTodasLasNotificacionesSinLeer/index",
  async (data) => {
    try {
      const response = await Notificaciones.fetchTodasLasNotificacionesSinLeer(
        data
      );
      return response;
    } catch (err) {
      const error_todas_las_notificaciones_sin_leer =
        "ERROR_FETCH_CANTIDAD_DE_NOTIFICACIONES";
      console.error(error_todas_las_notificaciones_sin_leer, err);
    }
  }
);
//## fetch todas las notificaciones ##//
export const fetchTodasLasNotificaciones = createAsyncThunk(
    "fetchTodasLasNotificaciones/index",
    async(data) => {
        try{    
            const response = await Notificaciones.fetchTodasLasNotificaciones(data);
            return response
        }catch(err){
            const error_todas_las_notificaciones = "ERROR_FETCH_TODAS_LAS_NOTIFICACIONES";
            console.error(error_todas_las_notificaciones, err);
        }
    }
);
export const patchNotificacion = createAsyncThunk(
  "patchNotificacion/index",
  async(data) => {
    try{
      await Notificaciones.patchNotificacion(data)
    }catch(err){
      const error_patch_notificacion = "ERROR_PATCH_NOTIFICACION";
      console.error(error_patch_notificacion, err)
    }
  }
)
const initialState = {
  cantidadDeNotificacionesData: null,
  todasLasNotificacionesSinLeerData: null,
  todasLasNotificacionesData:null,
};

const notificacionesSlice = createSlice({
  name: "notificaciones",
  initialState,
  reducers: {
    resetNotificaciones: (state) => initialState,
  },
  extraReducers: {
    //##  fetch cantidad de notificaciones ##//
    [fetchCantidadDeNotificaciones.fulfilled]: (state, action) => {
      state.cantidadDeNotificacionesData = action.payload;
    },
    //## fetch todas las notificaciones sin leer ##//
    [fetchTodasLasNotificacionesSinLeer.fulfilled]: (state, action) => {
      state.todasLasNotificacionesSinLeerData = action.payload;
    },
    //## fetch todas las notificaciones ##//
    [fetchTodasLasNotificaciones.fulfilled]: (state, action) => {
        state.todasLasNotificacionesData = action.payload;
    },
  },
});
export default notificacionesSlice;
