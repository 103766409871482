import { usuarioToken, usuarioId } from "../data/usuario";
const apiToken = "Bearer " + process.env.API_TOKEN;


const Listas = {
  getListasDeUsuario: async (sinFavoritos) => {
    try {
      const response = await fetch(
        `/index.php?ctrl=listas&act=obtenerListasUsuario&sinFavoritos=`+sinFavoritos,
        {
          method: "GET",
          headers: {
            Authorization: apiToken,
            usuarioid: usuarioId,
            usuariotoken: usuarioToken,
          },
        }
      );
      const listas = response.json();
      return listas;
    } catch (err) {
      const error = "ERROR_GET_LISTAS_DE_USUARIO";
      return error;
    }
  },
  crearListaDeUsuario: async (data) => {
    try {
      const response = await fetch(`index.php?ctrl=listas&act=crearLista`, {
        method: "POST",
        headers: {
          Authorization: apiToken,
          usuarioid: usuarioId,
          usuariotoken: usuarioToken,
        },
        body: JSON.stringify({
          titulo: data.titulo,
          es_publica: data.es_publica,
        }),
      });
      const mensajeDeRespuesta = response.json();
      return mensajeDeRespuesta;
    } catch (err) {
      const error = "ERROR_CREAR_LISTA_DE_USUARIO";
      return error;
    }
  },
  agregarQuitarProductoALista: async (data) => {
    try {
      //Dependiendo de del parametro agregarProducto cambiamos verbo.
      const verbo = data.agregarProducto == 0 ? "PATCH" : "POST";
      const response = await fetch(
        `index.php?ctrl=listas&act=agregarQuitarProductoALista`,
        {
          method: verbo,
          headers: {
            Authorization: apiToken,
            usuarioid: usuarioId,
            usuariotoken: usuarioToken,
          },
          body: JSON.stringify({
            tipo_lista: data.tipo_lista,
            lista_id: data.lista_id,
            producto_id: data.producto_id,
            agregarProducto: data.agregarProducto
          }),
        }
      );
      const resultado = response.json();
      return resultado;
    } catch (err) {
      const error = "ERROR_AGREGAR_PRODUCTO_A_LISTA_DE_USUARIO";
      return error;
    }
  },
  borrarTodosProductoDeLista: async (data) => {
    try {
      const response = await fetch(
        `index.php?ctrl=listas&act=borrarTodoLosProductosEnLista`,
        {
          method: "PATCH",
          headers: {
            Authorization: apiToken,
            usuarioid: usuarioId,
            usuariotoken: usuarioToken,
          },
          body: JSON.stringify({
            lista_id: data.lista_id,
          }),
        }
      );
      const resultado = response.json();
      return resultado;
    } catch (err) {
      const error = "ERROR_BORRAR_TODOS_PRODUCTOS_LISTA";
      return error;
    }
  },
};

export default Listas;
