import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/pro-light-svg-icons";
//## components ##//
import NotificacionesHeaderList from "./NotificacionesHeaderList";
import NotificacionesFullList from "./NotificacionesFullList";

//## rerux data ##//
import {
  fetchCantidadDeNotificaciones,
  fetchTodasLasNotificacionesSinLeer,
  fetchTodasLasNotificaciones,
  patchNotificacion,
} from "../redux/data/notificaciones";
import { getUsuario, usuarioToken } from "../redux/data/usuario";
const apiToken = process.env.API_TOKEN;

const NotificacionesLoguedIn = () => {
  const dispatch = useDispatch();
  const {
    cantidadDeNotificacionesData,
    todasLasNotificacionesSinLeerData,
    todasLasNotificacionesData,
  } = useSelector((state) => state.notificaciones);
  const id = useSelector(
    (state) =>
      state.usuario &&
      state.usuario.usuario.datos &&
      state.usuario.usuario.datos.usr_id
  );

  //## States ##//

  /* state con las cantidades de las notificaciones */
  const [restartEffect, setRestartEffect] = useState(false); //=> cambio el estado a true para traer los ultimos cambios
  /* state para manejar la visibilidad del panel de detalles de notificaicones */
  const [
    visibilidadDetalleNotificaciones,
    setVisibilidadDetalleNotificaciones,
  ] = useState(false);

  const headersAuth = {
    Usuarioid: id,
    Usuariotoken: usuarioToken,
    Authorization: `Bearer ${apiToken}`,
  };

  //## cargando dataos de cantidad de notificaciones ##//
  useEffect(() => {
    if (id) {
      dispatch(fetchCantidadDeNotificaciones(headersAuth));
      //## cargando datos de todas las notificaciones sin leer ##/
      dispatch(fetchTodasLasNotificacionesSinLeer(headersAuth));
      //## cargarndo datos de todas las notificaciones ##//
      dispatch(fetchTodasLasNotificaciones(headersAuth));
    }
    setRestartEffect(false);
  }, [restartEffect]);

  /* necesario para elminar scroll al mostrarse lista de notificacion */
  let documentBody = document.body;
  visibilidadDetalleNotificaciones
    ? (documentBody.style.overflow = "hidden")
    : (documentBody.style.overflow = "auto");

  const patchearNotificacion = (uiContext, accion, notificacionesId = []) => {
    const id = JSON.stringify({ ids: notificacionesId });
    dispatch(
      patchNotificacion({ data: uiContext, accion, id, headersAuth }),
      setRestartEffect(true)
    );
  };

  return (
    <>
      {/* INDICADOR DE CANTIDAD DE NOTIFICACIONES */}
      <IndicadorNotificacionesWrapper>
        {cantidadDeNotificacionesData &&
        cantidadDeNotificacionesData.cantidadSinLeer ? (
          <IndicadorCantidadNotificaciones>
            {cantidadDeNotificacionesData.cantidadSinLeer <= 10
              ? cantidadDeNotificacionesData.cantidadSinLeer
              : "+9"}
          </IndicadorCantidadNotificaciones>
        ) : null}
        <FontAwesomeIcon icon={faBell} size="lg" />
        {/*  resumen de notificaciones sin leer*/}

        <div className="notificaciones-header-list">
          <NotificacionesHeaderList
            cantidadDeNotificacionesSinLeer={
              cantidadDeNotificacionesData &&
              cantidadDeNotificacionesData.cantidadSinLeer
            }
            cantidadTotalDeNotificaciones={
              cantidadDeNotificacionesData &&
              cantidadDeNotificacionesData.cantidadTotal
            }
            notificaciones={todasLasNotificacionesSinLeerData}
            patchearNotificacion={patchearNotificacion}
            setVisibilidadDetalleNotificaciones={
              setVisibilidadDetalleNotificaciones
            }
            fetchTodasLasNotificaciones={fetchTodasLasNotificaciones}
          />
        </div>
      </IndicadorNotificacionesWrapper>

      {/* lista con todas las notificaciones */}
      {visibilidadDetalleNotificaciones &&
        todasLasNotificacionesData &&
        todasLasNotificacionesData.lista.length > 0 && (
          <NotificacionesFullList
            notificaciones={todasLasNotificacionesData}
            patchearNotificacion={patchearNotificacion}
            setVisibilidadDetalleNotificaciones={
              setVisibilidadDetalleNotificaciones
            }
          />
        )}
    </>
  );
};

const NotificacionesLoguedOff = () => {
  return (
    <IndicadorNotificacionesWrapper>
      <a href="login" aria-label="Iniciar sesión">
        <FontAwesomeIcon icon={faBell} size="lg" />
        <span>Inicia sesión en LOi</span>
      </a>
    </IndicadorNotificacionesWrapper>
  );
};

const Notificaciones = () => {
  const { usuario } = useSelector((state) => state.usuario);
  return usuario && usuario.logueado ? (
    <NotificacionesLoguedIn
      apiToken={apiToken}
      usuarioId={usuario.usr_id}
      usuarioToken={usuarioToken}
    />
  ) : (
    <NotificacionesLoguedOff />
  );
};
const IndicadorNotificacionesWrapper = styled.div`
  padding: 10px;
  border-radius: ${(props) => props.theme.mediumBorderRadius};
  > a {
    color: ${(props) => props.theme.primario};
    display: flex;
    &:hover {
      color: ${(props) => props.theme.primarioHover};
    }
    > span {
      display: none;
    }
  }
  svg {
    color: ${(props) => props.theme.primario};
  }

  position: relative;
  border: 0;
  background-color: transparent;
  > .notificaciones-header-list {
    display: none;
  }
  &:hover {
    color: ${(props) => props.theme.primarioHover};
    background-color: ${(props) => props.theme.secundarioClaro};
    > .notificaciones-header-list {
      display: block;
    }
  }
`;

const IndicadorCantidadNotificaciones = styled.div`
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 8px;
  font-weight: bold;
  color: white;
  background-color: ${(props) => props.theme.stop};
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 50%;
`;

export default Notificaciones;
