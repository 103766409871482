import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Fabricantes from "../services/fabricantes";

export const getFabricantes = createAsyncThunk(
  "getFabricantes/index",
  async () => {
    try {
      const response = await Fabricantes.getFabricantes();
      return response;
    } catch (err) {
      const error_fabricantes = "ERROR_GET_FABRICANTES";
      console.error(error_fabricantes, err);
    }
  }
);
const initialState = {};
const fabricantesSlice = createSlice({
  name: "fabricantes",
  initialState,
  reducers: {
    resetFabricantes: (state) => initialState,
  },
  extraReducers: {
    [getFabricantes.fulfilled]: (state, action) => {
      state.listaDeFabricantes = action.payload;
    },
  },
});
export default fabricantesSlice;
