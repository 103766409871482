import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
const HomeH1 = () => {
  const eslogan = useSelector((state) => state.empresa.empresaData && state.empresa.empresaData.eslogan)
  return (
    <MensajeH1Home>
      {eslogan && eslogan}   
    </MensajeH1Home>
  );
};

const MensajeH1Home = styled.h1`
  position: absolute;
  z-index: -1;
  border: 0;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  margin: 0 -1px -1px 0;
  overflow: hidden;
  padding: 0;
  display: block;
`;

export default HomeH1;
