// globalStyles.js

import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
tbody,
tfoot,
thead {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
}
body {
  font-family: ${(props) => props.theme.bodyFonts};
  font-weight: ${(props) => props.theme.bodyFontsweight};
  font-style: normal;
  font-size: 1rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin: 0px;
  line-height: 1.3;
  min-width: 320px;
  min-height: 100vh;
  background-color: ${(props) => props.theme.body};
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
  env(safe-area-inset-bottom) env(safe-area-inset-left);
}

a{
  color: ${(props) => props.theme.primario};
}


h1,h2,h3{
  font-family: ${(props) => props.theme.headersFont};
}

h1 {
  font-size: 22px;
}

h2 {
  font-size: 20px;
  color: ${(props) => props.theme.primarioHover};
}

h3 {
  font-size: 18px;
}

h6 {
  background-color: ${(props) => props.theme.primarioHover};
  font-size: 16px;
  padding: 15px 5px 15px 5px;
  margin: 5px 0 5px 0;
}
ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.contendor-general{
  width: 100%;
  padding: 0;
  margin: auto;
  margin-top: 0;
  margin-bottom:50px;
  max-width: 1440px;
  position: relative;
  @media screen and (max-width: ${(props) => props.theme.footerMobileVisibilityBreakPoint}) {
    margin-top: 0;
    margin-bottom:150px;
  }
}

`;



export default GlobalStyle;
