import React from "react";
import styled from "styled-components";
import Notificacion from "./Notificacion";

const NotificacionesLista = ({
  uiContext,
  patchearNotificacion,
  notificaciones,
}) => {
  return (
    <NotificacionesUl uiContext={uiContext}>
      {notificaciones && notificaciones.lista && notificaciones.lista.map((notificacion) =>
        uiContext === "header" ? (
          notificacion.leido ? null : (
            <Notificacion
              key={notificacion.id}
              notificacion={notificacion}
              patchearNotificacion={patchearNotificacion}
              uiContext={uiContext}
            />
          )
        ) : (
          <Notificacion
            key={notificacion.id}
            notificacion={notificacion}
            patchearNotificacion={patchearNotificacion}
            uiContext={uiContext}
          />
        )
      )}
    </NotificacionesUl>
  );
};
const NotificacionesUl = styled.ul`
  max-height: ${(props) => (props.uiContext === "header" ? "300px" : "auto")};
  flex: 2;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  text-align: left;
  div.opciones-notificacion {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    min-width: 30px;
    > button {
      margin: 0;
      padding: 5px;
      border: 0;
      background-color: transparent;
      > svg.fa-trash {
        color: gray;
      }
      &:hover {
        > svg.fa-trash {
          color: #004880;
        }
      }
    }
  }
`;

export default NotificacionesLista;
