import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHistory,
  faUserCircle,
  faUserTie,
  faMapMarker,
  faShoppingCart,
  faCreditCard,
  faFileSignature,
  faSignOut,
  faStar,
  faCogs,
  faList,
} from "@fortawesome/pro-light-svg-icons";

const OpcionesUsuarioLogueado = ({ configuraciones }) => {
  return (
    <ListaDeOpcionesUsuarioLogueado>
      <ArrowUpWrapper>
        <ArrowUp></ArrowUp>
      </ArrowUpWrapper>

      <li>
        <a aria-label="Datos Personales" href="/perfil/datos-personales">
          <FontAwesomeIcon icon={faUserCircle} />
          Datos Personales
        </a>
      </li>

      <li>
        <a aria-label="Favoritos" href="/perfil/listas">
          <FontAwesomeIcon icon={faList} />
          Favoritos y Listas
        </a>
      </li>

      <li>
        <a aria-label="Direcciones" href="/perfil/direcciones">
          <FontAwesomeIcon icon={faMapMarker} />
          Direcciones
        </a>
      </li>
      
      {configuraciones && configuraciones.ventaRutHabilitada && (
        <li>
          <a aria-label="Datos Empresariales" href="/perfil/empresas">
            <FontAwesomeIcon icon={faUserTie} />
            Empresas
          </a>
        </li>
      )}

      <li>
        <a aria-label="Compras" href="/perfil/compras">
          <FontAwesomeIcon icon={faShoppingCart} />
          Compras
        </a>
      </li>
      <li>
        <a aria-label="Tarjetas" href="/perfil/tarjetas">
          <FontAwesomeIcon icon={faCreditCard} />
          Tarjetas
        </a>
      </li>
      {configuraciones && configuraciones.prestaLoiActivo !== 0 && (
        <li>
          <a
            aria-label="Préstamos"
            href="index.php?ctrl=usuarios&act=prestamos&pagina=1"
          >
            <FontAwesomeIcon icon={faFileSignature} />
            Préstamos
          </a>
        </li>
      )}

      <li>
        <a aria-label="Historial" href="/perfil/historial">
          <FontAwesomeIcon icon={faHistory} />
          Historial
        </a>
      </li>

      <li>
        <a aria-label="opiniones" href="/perfil/opiniones">
          <FontAwesomeIcon icon={faStar} />
          Opiniones
        </a>
      </li>
      <li>
        <a aria-label="Preferiencias" href="/perfil/preferencias">
          <FontAwesomeIcon icon={faCogs} />
          Preferencias
        </a>
      </li>
      <li>
        <a aria-label="Cerrar Sesión" href="/perfil/logout">
          <FontAwesomeIcon icon={faSignOut} />
          Cerrar sesion
        </a>
      </li>
    </ListaDeOpcionesUsuarioLogueado>
  );
};

const ArrowUpWrapper = styled.div`
  background-color: transparent;
  height: 20px;
  position: absolute;
  width: 100%;
  top: -20px;
  @media screen and (max-width: 919px) {
  display :none;
  }
`;
const ArrowUp = styled.div`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  margin-left: -10px;
  border-bottom: 14px solid ${(props) => props.theme.primarioHover};
  position: absolute;
  top: 5px;
  left: 15px;
  @media screen and (max-width: 719px) {
  display :none;
  }
`;


const ListaDeOpcionesUsuarioLogueado = styled.ul`
  margin-top: 20px;
  display: none;
  position: absolute;
  background-color: white;
  width: 180px;
  border-radius: ${(props) => props.theme.smallBorderRadius};
  border: thin solid ${(props) => props.theme.primarioHover};
  z-index: 2;
  top: 25px;
  > li {
    display: block;
    min-height: 20px;
    a {
      text-decoration: none;
      color: black;
      font-size: 14px;
      display: block;
      padding: 10px;
      > svg {
        color: ${(props) => props.theme.primarioHover};
      }
    }
    a:hover {
      color: ${(props) => props.theme.primarioHover};
      text-decoration: underline;
    }
    span {
      color: red;
    }
    svg {
      margin-right: 10px;
    }
  }
  > img {
    float: left;
    padding: 4px;
    margin-right: 10px;
  }
  @media screen and (max-width: 919px) {
    background-color: white;
    flex-direction: column-reverse;
    top: auto;
    right: 10px;
    bottom: 55px;
    display: flex;
    > li {
      > a {
        font-size: 16px;
      }
    }
  }
`;

export default OpcionesUsuarioLogueado;
