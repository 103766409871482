import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
/*#### icons ####*/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faSpinner, faShoppingBag } from "@fortawesome/pro-light-svg-icons";
/*#### redux data ####*/
import { getCarritoHeader } from "../redux/data/carrito";

const CarritoHeader = () => {
  const dispatch = useDispatch();
  const globalConfig = useSelector((state) => state.empresa && state.empresa.globalConfig);
  const { carritoHeaderData } = useSelector((state) => state.carrito);

  useEffect(() => {
    dispatch(getCarritoHeader());
  }, []);

  return (
    <ContainerPreview>
      <a href="carrito">
        {globalConfig.iconoDeCarrito === 'cart' ? (
          <FontAwesomeIcon icon={faShoppingCart} size={'lg'} fixedWidth />
        ) : (
          <FontAwesomeIcon icon={faShoppingBag} size={'lg'} fixedWidth/>
        )}
        {carritoHeaderData ? (
          <Cantidad>{carritoHeaderData.cantidad}</Cantidad>
        ) : (
          <Cantidad><FontAwesomeIcon icon={faSpinner} spin/></Cantidad>
        )}
      </a>
    </ContainerPreview>
  );
};
const ContainerPreview = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  a {
    border-radius: ${(props) => props.theme.mediumBorderRadius};
    padding: 10px;
    color: ${(props) => props.theme.primario};
  }
  a:hover {
    color: ${(props) => props.theme.primarioHover};
    background-color: ${(props) => props.theme.secundarioClaro};
  }
`;
const Cantidad = styled.div`
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 8px;
  font-weight: bold;
  color: white;
  background-color: ${(props) => props.theme.stop};
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 50%;
`;

export default CarritoHeader;
