import React from 'react';
import styled from 'styled-components';
/*#### icons ####*/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy } from "@fortawesome/pro-light-svg-icons";

const BotonPencaHeader = () => {
  return ( 
    <BotonPenca href='/penca'><FontAwesomeIcon style={{"--fa-animation-iteration-count": 3}} icon={faTrophy } size="lg" bounce /><span>Penca LOi</span></BotonPenca>
   );
}
 
const BotonPenca = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #1d328b;
  padding: 8px 15px;
  font-weight: bold;
  border-radius: 100px;
  text-decoration: none;
  > svg{
    margin-right: 5px;
    @media screen and (max-width: 1140px) {
      margin-right: 0;
    }
  }
  >span{
    display: block;
    white-space: nowrap;
    @media screen and (max-width: 1140px) {
      display: none;
    }
  }

  &:hover{
    background: white;
    color: #7F1431;
  }
`;
export default BotonPencaHeader;