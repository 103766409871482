import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/pro-light-svg-icons";
import NotificacionesLista from './notificaciones/NotificacionesLista';

const NotificacionesHeaderList = ({
  cantidadDeNotificacionesSinLeer,
  cantidadTotalDeNotificaciones,
  notificaciones,
  patchearNotificacion,
  setVisibilidadDetalleNotificaciones,
  fetchTodasLasNotificaciones,
}) => {
  return (
    <ListaNotificacionesWrapper>
      <ArrowUpWrapper>
        <ArrowUp></ArrowUp>
      </ArrowUpWrapper>
      <ListaNotificaciones>
        {
          cantidadDeNotificacionesSinLeer > 0 ? (
            <NotificacionesLista uiContext={'header'} patchearNotificacion={patchearNotificacion} notificaciones={notificaciones} />
          ) : (
            <AvisoNotificacionesSinLeer>
              <FontAwesomeIcon size={"2x"} icon={faCheckDouble} />
              No tenés notificaciones sin leer
            </AvisoNotificacionesSinLeer>
          )
        }
        <footer>
          {cantidadDeNotificacionesSinLeer > 0 ? (
            <button
              onClick={
                () => {
                  patchearNotificacion('header', "marcarNotificacionesLeidas");
                }
              }

              className="boton-opciones-lista-notificaciones"
            >
              Marcar todas como leidas
            </button>
          ) : null}

          {cantidadTotalDeNotificaciones > 0 ? (
            <a
              className="boton-opciones-lista-notificaciones"
              onClick={() => {
                setVisibilidadDetalleNotificaciones(true);
                fetchTodasLasNotificaciones();
              }}
            >
              Ver todas
            </a>
          ) : null}
        </footer>
      </ListaNotificaciones>
    </ListaNotificacionesWrapper>
  );
};
const ArrowUpWrapper = styled.div`
  background-color: transparent;
  height: 20px;
`;
const ArrowUp = styled.div`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 14px solid ${(props) => props.theme.primarioHover};
  position: absolute;
  top: 6px;
  right: 55px;
`;

const ListaNotificacionesWrapper = styled.section`
  position: absolute;
  right: -42px;
  top: 20px;
  width: 300px;
  display: flex;
  flex-direction: column;
  z-index: 4;
`;
const ListaNotificaciones = styled.div`
  background-color: white;
  border-radius: ${(props) => props.theme.smallBorderRadius};
  border:thin solid ${(props) => props.theme.primarioHover};
  > h3 {
    font-size: 14px;
    text-align: center;
    flex: 1;
    margin: 0;
    padding: 10px 0;
    align-self: flex-start;
  }
  > footer {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0;
    padding: 0 0 5px 0;
    align-self: flex-end;
    border-top: 1px solid ghostwhite;
    a.boton-opciones-lista-notificaciones, button.boton-opciones-lista-notificaciones {
      cursor: pointer;
      font-size: 10px;
      line-height: 15px;
      padding: 3px 10px;
      margin: 5px;
      color: #004880;
      border: 1px solid white;
      border-radius: 20px;
      text-decoration: none;
      background-color: white;
      &:hover {
        border-color: #004880;
      }
    }
  }
`;
const AvisoNotificacionesSinLeer = styled.section`
  color: ${(props) => props.theme.primario};
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  > a{
    text-align: center;
    text-decoration: none;
    color: ${(props) => props.theme.primarioHover};
  }
  > svg {
    padding: 20px;
  }
`;
export default NotificacionesHeaderList;
