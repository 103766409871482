import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Tendencias from "../services/tendencias";

//get datos de tendencias
export const getTendencias = createAsyncThunk(
  "getTendencias/index",
  async () => {
    try {
      const response = await Tendencias.getTendencias();
      return response;
    } catch (err) {
      const error_tendencias = "ERROR_GET_TENDENCIAS";
      console.error(error_tendencias, err);
    }
  }
);
const initialState = {
  //states tendencias -- tarjetas de productos ---
  tendenciasItems: null,
  //end states tendencias -- tarjetas de productos ---
};
//slice
const tendenciasSlice = createSlice({
  name: "tendencias",
  initialState,
  reducers: {
    resetTendencias: (state) => initialState,
  },
  extraReducers: {
    //get tendencias --tarjetas de productos ---
    [getTendencias.fulfilled]: (state, action) => {
      state.tendenciasItems = action.payload;
    },
    // end get tendencias --tarjetas de productos --
  },
});
export default tendenciasSlice;
