const Carrito = {
    //datos de carrito en header
  getCarritoHeader: async () => {
    try {
      const response = await fetch(
        "index.php?ctrl=index&act=obtenerCarritoHeader",
        {
          method: "GET",
        }
      );
      const carritoHeaderData = await response.json();
      return carritoHeaderData;
    } catch (err) {
      const error = "ERROR_GET_CARRITO_HEADER_SERVICE";
      return error;
    }
  },
  //agregar productos al carrito por id y cantidad
  agregarRestarProductoCarrito: async (data) => {
    try {
      const response = await fetch(
        "index.php?ctrl=carrito&act=agregar&prod_cant=" +
          data.cantidad +
          "&prod_id=" +
          data.id +
          "&noRedirigir=1" +
          "&prod_cant_total=1",
        {
          method: "GET",
        }
      );
      const agregarRestarCarrito = await response.json();

      if(document.getElementById("esApp").value == "1"){
        //Event.
        (function(){
          window.ReactNativeWebView.postMessage(JSON.stringify({
                cartUpdate: true,
                cantidadEnCarrito: agregarRestarCarrito.cantidad_en_carrito
            }));
        })();
      }
      
      /*ACCIONES PARA GOOGLE ANALTICS G4*/
      agregarRestarCarrito.cantidad = 1
      if(data.cantidad > 0){
        addToCart([agregarRestarCarrito[0]]);
        procesarFacebookConversionApi(JSON.stringify({"metodo": "addToCart", "data": agregarRestarCarrito[0], "url": location.pathname}));
      }else{
        removeFromCart([agregarRestarCarrito[0]]);
      }
      /*ACCIONES PARA GOOGLE ANALTICS G4*/

      return agregarRestarCarrito;
    } catch (err) {
      const error = "ERROR_AGREGAR_RESTAR_PRODUCTO_CARRITO_SERVICE";
      return error;
    }
  },
};
export default Carrito