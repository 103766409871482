import { usuarioToken, usuarioId } from "../data/usuario";
const apiToken = "Bearer " + process.env.API_TOKEN;
const headerData = {
  Usuarioid: usuarioId,
  Usuariotoken: usuarioToken,
  Authorization: apiToken,
};

const Tendencias = {
  // datos para tarjetas de productos --tendencias--
  getTendencias: async () => {
    try {
      const response = await fetch("/index.php?ctrl=index&act=tendencias", {
        method: "GET",
        headers: headerData,
      });
      const tendenciasData = await response.json();
      return tendenciasData;
    } catch (err) {
      const error = "ERROR_GET_TENDENCIAS_SERVICE";
      return error;
    }
  },
};
export default Tendencias;
