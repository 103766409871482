const apiToken = process.env.API_TOKEN;
const Localidades = {
    getLocalidades: async(id) => {
        try{
            const response = await fetch("/index.php?ctrl=index&act=obtenerLocalidadesPorDepartamento&deptoId=" +
            id,{
                method: "GET",
                headers: { Authorization: "Bearer " + apiToken },
              })
              const responseLocalidades = response.json();
              return responseLocalidades;
        }catch(err){
            const error = "ERROR_GET_LOCALIDADES_SERVICE";
            return error;
        }
    }
}
export default Localidades;