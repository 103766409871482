var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { usuarioToken, usuarioId } from "../data/usuario";
var apiToken = "Bearer " + process.env.API_TOKEN;
var ListadoProductos = {
    getListadoProductos: function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var tipoListado, posicionEnLista, listaId, esPublica, primeraCargaConFiltro, newFiltros, claves, i, clave, fetchUrl, cantidad, orden, precio, min_max, envio, filtro, filtroBool, headerData, categ_id, coleccion, categ_id, usuario_id, categ_id, fabId, relevancia, response, listadoProductosData, err_1, error;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    tipoListado = +document.getElementById("tipoListado").value;
                    posicionEnLista = data.posicionEnLista, listaId = data.listaId, esPublica = data.esPublica, primeraCargaConFiltro = data.primeraCargaConFiltro;
                    newFiltros = {};
                    claves = typeof filtrosListados !== 'undefined' && Object.keys(filtrosListados).length > 0 ? Object.keys(filtrosListados) : "";
                    for (i = 0; i < claves.length; i++) {
                        clave = claves[i];
                        if (clave !== "marca") {
                            //@ts-ignore
                            newFiltros["filtro-" + clave] = filtrosListados[clave];
                        }
                        else {
                            //@ts-ignore
                            newFiltros["marca"] = filtrosListados[clave];
                        }
                    }
                    cantidad = posicionEnLista;
                    orden = document.getElementById("orden") ? document.getElementById("orden").value : "";
                    precio = document.getElementById("precio") ? document.getElementById("precio").value : "";
                    min_max = document.getElementById("min_max") ? document.getElementById("min_max").value : "";
                    envio = document.getElementById("ver-recibo-2horas") && document.getElementById("ver-recibo-2horas").checked ? "1" : "0";
                    filtro = typeof filtrosListados !== 'undefined' && Object.keys(filtrosListados).length > 0 ? "&" + new URLSearchParams(newFiltros).toString() : "";
                    filtroBool = typeof filtrosListados !== 'undefined' && Object.keys(filtrosListados).length > 0;
                    headerData = {};
                    /* listas personalizadas */
                    if (listaId) {
                        //Listas y favoritos
                        headerData = {
                            Usuarioid: usuarioId,
                            Usuariotoken: usuarioToken,
                            Authorization: apiToken,
                        };
                        fetchUrl = "/index.php?ctrl=listas&act=obtenerProductosLista&listaId=".concat(listaId, "&esPublica=").concat(esPublica);
                    }
                    if (tipoListado == 1) {
                        categ_id = categId;
                        coleccion = document.getElementById("coleccion").value;
                        fetchUrl = "/index.php?ctrl=colecciones&act=mostrarArticulos&coleccion=".concat(coleccion, "&cantidad=").concat(cantidad, "&orden=").concat(orden, "&precio=").concat(precio, "&min_max=").concat(min_max, "&categ_id=").concat(categ_id, "&envio=").concat(envio).concat(filtro, "&filtroBool=").concat(filtroBool, "&primeraCargaConFiltro=").concat(primeraCargaConFiltro, "&react=1");
                    }
                    else if (tipoListado == 2) {
                        //Categoria
                        orden = orden === "" && document.getElementById("mostrarListado").value == "" ? "personalize" : orden;
                        categ_id = document.getElementById("categ_id").value;
                        usuario_id = document.getElementById("usr_id") ? document.getElementById("usr_id").value : 1;
                        fetchUrl = "/index.php?ctrl=productos&act=categoriasReact&categ_id=".concat(categ_id, "&usuario_id=").concat(usuario_id, "&cantidad=").concat(cantidad, "&orden=").concat(orden, "&precio=").concat(precio, "&min_max=").concat(min_max, "&envio=").concat(envio).concat(filtro, "&filtroBool=").concat(filtroBool, "&primeraCargaConFiltro=").concat(primeraCargaConFiltro);
                    }
                    else if (tipoListado == 3) {
                        categ_id = categId;
                        fabId = document.getElementById("fab_id").value;
                        relevancia = document.getElementById("relevancia") ? document.getElementById("relevancia").value : 0;
                        //@ts-ignore
                        fetchUrl = "/index.php?ctrl=tiendas&act=obtenerProductosPorTienda&fab_id=".concat(fabId, "&cantidad=").concat(cantidad, "&orden=").concat(orden, "&precio=").concat(precio, "&min_max=").concat(min_max, "&categ_id=").concat(categ_id, "&envio=").concat(envio).concat(filtro, "&filtroBool=").concat(filtroBool, "&primeraCargaConFiltro=").concat(primeraCargaConFiltro, "&relevancia=").concat(relevancia, "&react=1");
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, fetch(fetchUrl, {
                            method: "GET",
                            headers: headerData,
                        })];
                case 2:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 3:
                    listadoProductosData = _a.sent();
                    return [2 /*return*/, listadoProductosData];
                case 4:
                    err_1 = _a.sent();
                    error = "ERROR_GET_LISTADO_PRODUCTOS_SERVICE";
                    return [2 /*return*/, error];
                case 5: return [2 /*return*/];
            }
        });
    }); },
    reorderListadoProductos: function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var response, listadoProductosData, err_2, error;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, fetch('/index.php?ctrl=listas&act=reorderListadoProductos', {
                            method: "POST",
                            headers: {
                                Authorization: apiToken,
                                usuarioid: usuarioId,
                                usuariotoken: usuarioToken,
                            },
                            body: JSON.stringify({ data: data }),
                        })];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    listadoProductosData = _a.sent();
                    return [2 /*return*/, listadoProductosData];
                case 3:
                    err_2 = _a.sent();
                    error = "ERROR_GET_REORDER_LISTADO_PRODUCTOS";
                    return [2 /*return*/, error];
                case 4: return [2 /*return*/];
            }
        });
    }); }
};
export default ListadoProductos;
