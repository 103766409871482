import { usuarioToken, usuarioId } from "../data/usuario";
const apiToken = "Bearer " + process.env.API_TOKEN;

const Favoritos = {
     //agregar o eliminar producto de tendencias de favoritos
  obtenerFavoritosUsuario: async (data) => {
    const headerData = {
      Usuarioid: usuarioId,
      Usuariotoken: usuarioToken,
      Authorization: apiToken,
    };
    try {
      const response = await fetch('index.php?ctrl=index&act=obtenerFavoritosUsuario',
        {
          method: "GET",
          headers: headerData,
        });
        const listadoProductosData = await response.json();
        return listadoProductosData;
    } catch (err) {
      const error = "ERROR_OBTENER_FAVORITO";
      return error;
    }
  },
};
export default Favoritos;