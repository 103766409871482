import { configureStore } from "@reduxjs/toolkit";
import tendenciasSlice from "./tendencias";
import novedadesSlice from "./novedades";
import favoritosSlice from "./favoritos";
import listasSlice from "./listas";
import carritoSlice from "./carrito";
import recomendadosSlice from "./recomendados";
import empresaSlice from "./empresa";
import categoriasSlice from "./categorias";
import notificacionesSlice from "./notificaciones";
import novedadesPorCategoriaDestacadaSlice from "./novedadesPorCategoriaDestacada";
import usuarioSlice from "./usuario";
import tiendasOficialesSlice from "./tiendasOficiales";
import departamentosSlice from "./departamentos";
import localidadesSlice from "./localidades";
import coleccionesSlice from "./colecciones";
import listadoProductosSlice from "./listadoProductos";
import fabricantesSlice from "./fabricantes";
import rastrearCompraSlice from "./rastrearCompra";
import modalSlice from "./modal";
/* con que queremos quedarnos en el store como app state
empresa,
usario,
carrito,
notificaciones,
departamentos,
localidades,
modal
*/
var store = configureStore({
    reducer: {
        empresa: empresaSlice.reducer,
        usuario: usuarioSlice.reducer,
        carrito: carritoSlice.reducer,
        notificaciones: notificacionesSlice.reducer,
        categorias: categoriasSlice.reducer,
        tendencias: tendenciasSlice.reducer,
        novedades: novedadesSlice.reducer,
        favoritos: favoritosSlice.reducer,
        recomendados: recomendadosSlice.reducer,
        novedadesPorCategoriaDestacada: novedadesPorCategoriaDestacadaSlice.reducer,
        tiendasOficiales: tiendasOficialesSlice.reducer,
        colecciones: coleccionesSlice.reducer,
        listadoColeccionSlice: listadoProductosSlice.reducer,
        fabricantes: fabricantesSlice.reducer,
        departamentos: departamentosSlice.reducer,
        localidades: localidadesSlice.reducer,
        rastrearCompra: rastrearCompraSlice.reducer,
        listas: listasSlice.reducer,
        modal: modalSlice.reducer,
    },
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ["modal/openModal"],
                ignoredPaths: ["modal.data"],
            },
        });
    },
});
export default store;
