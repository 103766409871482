import { usuarioToken, usuarioId } from "../data/usuario";
const apiToken = "Bearer " + process.env.API_TOKEN;
const headerData = {
  Usuarioid: usuarioId,
  Usuariotoken: usuarioToken,
  Authorization: apiToken,
};

const Fabricantes = {
    getFabricantes: async () => {
        try{
            const response = await fetch(`/index.php?ctrl=index&act=fabricantes`,{
                method: "GET",
                headers: headerData,
            });
            const responseFabricantes = response.json();
            return responseFabricantes;
        }catch(err){
            const error = "ERROR_GET_FABICANTES_SERVICE";
            return error;
        }
    }
}
export default Fabricantes;