import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Departamentos from "../services/departamentos";

export const getDepartamentos = createAsyncThunk("getDepartamentos/index", async()=>{
    try{
        const response = await Departamentos.getDepartamentos();
        return response
    }catch(err){
        const error_departamentos = "ERROR_GET_DEPARTEMENTOS";
        console.error(error_departamentos, err)
    }
});
const initialState = {
    departamentos:null
};
const departamentosSlice = createSlice({
    name:"departamentos",
    initialState,
    reducers: {
        resetDepartamentos:(state) => initialState,
    },
    extraReducers: {
        [getDepartamentos.fulfilled]:(state, action) => {
            state.departamentos = action.payload;
        }
    }
});
export default departamentosSlice;