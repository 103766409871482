import React, { useEffect, useState, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkedAlt,
  faTimes,
  faCheck,
} from "@fortawesome/pro-light-svg-icons";
import { useDispatch, useSelector } from "react-redux";

//## redux data ##//
import { getLocalidades } from "../redux/data/localidades";
import { getEmpresa } from "../redux/data/empresa";

const PopUpDestinoDeEnvio = ({
  paisActivo,
  usuarioLogueado,
  usuarioDireccion,
  setVisibilidadPopUpDestinoEnvio,
  departamentos,
  departamentoIdLs,
  localidadIdLs,
  guardarLocalidadEnCookie,
}) => {
  const dispatch = useDispatch();
  const { localidades } = useSelector((state) => state.localidades);
  const [departamentoId, setDepartamentoId] = useState("");
  const [localidadId, setLocalidadId] = useState(null);
  const departamentoSelect = useRef(null);
  const localidadSelect = useRef(null);

  useEffect(() => {
    if (!usuarioLogueado) {
      /* si viene un departamento id de local storage lo seteo como el value */
      if (departamentos) {
        departamentoIdLs
          ? (departamentoSelect.current.value = departamentoIdLs)
          : null;
        setDepartamentoId(departamentoIdLs);
      }
      setLocalidadId(localidadIdLs);
    }
  }, []);

  useEffect(() => {
    if (!usuarioLogueado && departamentoId) {
      dispatch(getLocalidades(departamentoId));
      dispatch(getEmpresa());
    }
  }, [departamentoId]);

  const onGuardarDestino = () => {
    let currentDepartamentoId = departamentoSelect.current.value;
    let currentLocalidadId = localidadSelect.current.value;
    let currentLocalidadNombre =
      localidadSelect.current.options[
        localidadSelect.current.options.selectedIndex
      ].text;
    localStorage.setItem("localidadNombre", currentLocalidadNombre);
    localStorage.setItem("departamentoId", currentDepartamentoId);
    localStorage.setItem("localidadId", currentLocalidadId);
    guardarLocalidadEnCookie(currentLocalidadId);
    window.location.reload();
  };

  return (
    <ContendorBoxPopUpDestinoDeEnvio
      onClick={() => setVisibilidadPopUpDestinoEnvio(false)}
    >
      <BoxPopUpDestinoDeEnvio
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <header>
          <h3>
            <FontAwesomeIcon icon={faMapMarkedAlt} /> Destino de envio
          </h3>
        </header>
        <section>
          <p>
            Usamos el destino de envío para mostrarte los tipos y costos de
            envío que más te convengan.
          </p>
          {usuarioLogueado ? (
            usuarioDireccion ? (
              <>
                <BoxDatosDestinoEnvio>
                  <b>Enviando a: </b>
                  {usuarioDireccion.du_nombre}.
                  <div>
                    {usuarioDireccion.nom_dpto}
                    {" > "}
                    {usuarioDireccion.loc_nombre}
                    {" > "}
                    {usuarioDireccion.du_direccion} {usuarioDireccion.du_numero}
                  </div>
                </BoxDatosDestinoEnvio>
                <a
                  className="boton-opcion-destino-envio"
                  href="/perfil/direcciones"
                >
                  Cambiar Dirección
                </a>
              </>
            ) : (
              <article>
                <p>No tienes ninguna dirección en tu cuenta.</p>
                <a
                  className="boton-opcion-destino-envio"
                  href="/perfil/direcciones"
                >
                  Crear una dirección de envío
                </a>
              </article>
            )
          ) : (
            <article>
              {departamentos ? (
                <div>
                  <label>{paisActivo === 1 ? "Departamento" : "Ciudad"}</label>
                  <select
                    ref={departamentoSelect}
                    name="departamento"
                    id="select_departamento"
                    onChange={(e) => {
                      setDepartamentoId(e.target.value);
                      setLocalidadId(null);
                    }}
                  >
                    <option value={0}>
                      {paisActivo === 1
                        ? "Selecciona un departamento."
                        : "Selecciona una ciudad."}
                    </option>
                    {departamentos &&
                      departamentos.map((departamento) => (
                        <option key={departamento.id} value={departamento.id}>
                          {departamento.nombre}
                        </option>
                      ))}
                  </select>
                </div>
              ) : (
                <div>Cargando…</div>
              )}
              {departamentoId && departamentoId !== 0 ? (
                <div>
                  <label>{paisActivo === 1 ? "Localidad" : "Comuna"}</label>
                  {localidades && localidades.length > 0 && (
                    <select
                      ref={localidadSelect}
                      name="localidad"
                      id="select_localidad"
                      onChange={(e) => {
                        setLocalidadId(e.target.value);
                      }}
                      value={localidadId}
                    >
                      {localidades.map((localidad) => (
                        <option key={localidad.id} value={localidad.id}>
                          {localidad.nombre}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              ) : (
                <div className="aviso-seleccion-depto">
                  {paisActivo === 1
                    ? "Por favor, selecciona un departamento."
                    : "Por favor, selecciona una ciudad."}
                </div>
              )}
              <footer>
                <button
                  className="boton-opcion-destino-envio"
                  onClick={() => setVisibilidadPopUpDestinoEnvio(false)}
                >
                  <FontAwesomeIcon icon={faTimes} /> Cancelar
                </button>

                <button
                  className="boton-opcion-destino-envio"
                  onClick={() => {
                    onGuardarDestino();
                    setVisibilidadPopUpDestinoEnvio(false);
                  }}
                >
                  Guardar <FontAwesomeIcon icon={faCheck} />
                </button>
              </footer>
            </article>
          )}
        </section>
      </BoxPopUpDestinoDeEnvio>
    </ContendorBoxPopUpDestinoDeEnvio>
  );
};

const ContendorBoxPopUpDestinoDeEnvio = styled.section`
  background-color: ${(props) => props.theme.fondoPopUp};
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BoxPopUpDestinoDeEnvio = styled.section`
  text-align: center;
  margin: 15px;
  background-color: white;
  border-radius: ${(props) => props.theme.generalBorderRadius};
  position: absolute;
  overflow: hidden;
  box-shadow: ${(props) => props.theme.generalShadhow};
  div.aviso-seleccion-depto {
    font-size: 14px;
    color: gray;
    padding: 10px;
  }
  p {
    margin: 10px;
  }
  div {
    > label {
      font-size: 12px;
      display: block;
      text-align: center;
      color: ${(props) => props.theme.primarioHover};
      margin-bottom: 5px;
    }
    > select {
      padding: 10px;
      font-size: 16px;
      margin-bottom: 5px;
      border-radius: 4px;
      border: 1px solid ${(props) => props.theme.primarioHover};
      width: 240px;
    }
  }
  > header {
    padding: 10px;
    background-color: ${(props) => props.theme.primarioHover};
    color: white;
    h3 {
      margin: 0;
      padding: 0;
      font-size: 18px;
      font-weight: normal;
      color: white !important;
    }
    p {
      font-size: 14px;
      margin: 0;
      padding: 0;
    }
  }
  > section {
    padding: 10px;
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .boton-opcion-destino-envio {
    text-transform: uppercase;
    display: inline-block;
    text-align: center;
    margin: 0;
    padding: 10px 20px;
    border: 0;
    color: white;
    background-color: ${(props) => props.theme.primarioHover};
    text-decoration: none;
    border-radius: ${(props) => props.theme.totalBorderRadius};
    &:hover {
      background-color: ${(props) => props.theme.primario};
      cursor: pointer;
    }
  }

  > section > article > footer {
    padding: 10px;
  }
`;
const BoxDatosDestinoEnvio = styled.div`
  border: 1px solid ${(props) => props.theme.primarioHover};
  border-radius: ${(props) => props.theme.mediumBorderRadius};
  margin: 10px 0;
  padding: 10px;
  div {
    color: gray;
  }
`;
export default PopUpDestinoDeEnvio;
