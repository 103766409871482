import React, {useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InView } from "react-intersection-observer";
import loadable from '@loadable/component'
import ComunidadorCantidadCarrito from "./ComunicadorCantidadCarrito";
import Header from "./Header/Header";
/***********************
ZONA SUPERIOR PRIMERA CARGA
***********************/
import HomeH1 from "./HomeH1";
const Anuncios = loadable(() => import( "./Anuncios")); //NO USA LOADING
const AvisoCompletarDatos = loadable(() => import('./AvisoCompletarDatos')); //NO USA LOADING
const BannersHeroHome = loadable( () => import("./sliders/BannersHeroHome/BannersHeroHome")); //SI USA LOADING
const PagosHome = loadable(() => import("./sliders/PagosHome/PagosHome")); //SI USA LOADING
const InformacionCompraReciente = loadable(() => import("./InformacionCompraReciente/InformacionCompraReciente")); //NO USA LOADING
const HotSaleHome = loadable( () => import("./sliders/HotSaleHome/HotSaleHome")); //SI USA LOADING
const CategoriasBox = loadable(() => import("./sliders/CategoriasBox/CategoriasBox")); //SI USA LOADING
/***********************
 ZONA MEDIA SEGUNDA CARGA
 ***********************/
const ProductosRecomendadosUsuarioHome = loadable(/* webpackPrefetch: true */ () =>import("./sliders/ProductosRecomendadosUsuario/ProductosRecomendadosUsuario")); //SI USA LOADING
const NovedadesHome = loadable(/* webpackPrefetch: true */ () =>import("./sliders/NovedadesHome/NovedadesHome"));  //SI USA LOADING
const Top10Slider = loadable(/* webpackPrefetch: true */ () => import("./sliders/Top10Slider/Top10Slider")); //SI USA LOADING
const BannerCanjeOcaMibe = loadable(/* webpackPrefetch: true */ () => import("./BannerCanjeOcaMibe")); //NO USA LOADING
const SeccionesDestacadasHome = loadable(/* webpackPrefetch: true */ () =>import("./SeccionesDestacadasHome")); //NO USA LOADING
const ColeccionesHome = loadable(/* webpackPrefetch: true */ () =>import("./sliders/ColeccionesHome/ColeccionesHome")); //SI USA LOADING
const InvitacionARegistro = loadable(/* webpackPrefetch: true */ () => import("./InvitacionARegistro")); //NO USA LOADING
/***********************
 ZONA INFERIOR - TERCERA CARGA
 ***********************/
const NovedadesCategoria = loadable(/* webpackPrefetch: true */() =>import("./sliders/NovedadesCategorias/NovedadesCategorias")); //SI USA LOADING
const TiendasBannersHome = loadable(/* webpackPrefetch: true */() => import("./TiendasBannersHome")); //NO USA LOADING
const TendenciasHome = loadable(/* webpackPrefetch: true */() => import("./TendenciasHome")); //SI USA LOADING
const TiendasBox = loadable(/* webpackPrefetch: true */() => import("./sliders/TiendasBox/TiendasBox")); //SI USA LOADING
const TextoSeo = loadable(/* webpackPrefetch: true */() => import("./TextoSeo")); //SI USA LOADING
const Footer = loadable(/* webpackPrefetch: true */() => import("./Footer")); //SI USA LOADING
const MenuFooterMobile = loadable(/* webpackPrefetch: true */() => import("./MenuFooterMobile")); //SI USA LOADING
/***********************
 IMPORTS DINAMICOS INDEPENDIENTES
 ***********************/
const PopUpAgregarALista = loadable(/* webpackPrefetch: true */() => import("./PopUpAgregarALista"));
const Modal = loadable(/* webpackPrefetch: true */() => import("../modules/Modal/Modal"));

//## redux ##//
import { getEmpresa } from "../redux/data/empresa";
import { getUsuario } from "../redux/data/usuario";
import { getEnviosDisponibles } from "../redux/data/empresa";
import { obtenerFavoritosUsuario } from "../redux/data/favoritos";


const HomeLoader = () => {
  const esApp = document.getElementById("esApp").value === "1";
  const esHome = document.getElementById("esHome").value === "1";
  const usuario = useSelector(
    (state) =>
      state.usuario && state.usuario.usuario && state.usuario.usuario.datos
  );
  const dispatch = useDispatch();

  const esMobile = useSelector(
    (state) =>
      state.empresa &&
      state.empresa.empresaData &&
      state.empresa.empresaData.configuraciones &&
      state.empresa.empresaData.configuraciones.esMobile
  );

  const componentes = useSelector(
    (state) =>
      state.empresa &&
      state.empresa.empresaData &&
      state.empresa.empresaData.componentes
  );

  useEffect(() => {
    dispatch(getEmpresa());
    dispatch(getUsuario());
    dispatch(getEnviosDisponibles()); // => estados de datos --envios disponibles --
  }, []);

  useEffect(() => {
    //solo usuario logueado
    if (usuario !== null && usuario !== undefined) {
      dispatch(obtenerFavoritosUsuario());
    }
  }, [usuario]);

  const [visibilidadZonaMedia, setVisibilidadZonaMedia] = useState(false);
  const [visibilidadZonaInferior, setVisibilidadZonaInferior] = useState(false);

  if (esHome && usuario && usuario.usr_estado == 3) {
    window.location.href = "/perfil";
    return null;
  }

  return (
    <>
      <ComunidadorCantidadCarrito />
      {!esApp && <Header />}
      {esHome && componentes && (
        <>
          <main className="contendor-general">
            <div style={{minHeight:1000}}>
            {/*  h1 para seo */}
            <HomeH1 />
            {/* auncios en la home */}
            <Anuncios />
            {/*  aviso de completar datos */}
            <AvisoCompletarDatos/>
            {/*  banners en la home */}
            <BannersHeroHome className={"home"} />
            {/*  metodos de pago en home */}
            {componentes?.METODOS_DE_PAGO_EN_HOME && <PagosHome />}
            {/* informacion de compra reciente */}
            <InformacionCompraReciente/>
            {/* hot sale en loi */}
            {componentes?.HOME_HOT_SALE && (<HotSaleHome />)}
            {/*  shortcuts a categorias */}
            {componentes?.HOME_CATEGORIAS_RAPIDAS && (<CategoriasBox/>)}
            </div>

            <InView as="section"
              onChange={(inView, entry) =>
                inView === true && setVisibilidadZonaMedia(true)
              }
            >
              {visibilidadZonaMedia ? (
                <>
                  {/* productos recomendados, retail rocket */}
                  {componentes?.HOME_RECOMENDADOS_USUARIO && (<ProductosRecomendadosUsuarioHome />)}
                  {/* novedades general */}
                  <NovedadesHome />
                  {/* top 10 */}
                  {componentes?.HOME_PRODUCTOS_TOP && (<Top10Slider />)}
                  {/* banner de canje oca, SOLO SE CARGA EN MIBE */}
                  {componentes?.HOME_BANNER_COMPLETO_OCA_METRAJE && (<BannerCanjeOcaMibe />)}
                  {/* secciones destacadas en la home */}
                  {componentes?.HOME_SECCIONES_DESTACADAS && (<SeccionesDestacadasHome />)}
                  {/* colecciones */}
                  <ColeccionesHome />
                  {/* invitacion a registro */}
                  {componentes?.HOME_INVITACION_REGISTRO && (<InvitacionARegistro />)}
                </>
              ) : (
                <div style={{minHeight:2000}}></div>
              )}
            </InView>

            <InView as="section"
              onChange={(inView, entry) =>
                inView === true && setVisibilidadZonaInferior(true)
              }
            >
              {visibilidadZonaInferior ? (
                <>
                  {/* novedades por categoria */}
                  <NovedadesCategoria  />
                  {/* banners de tiendas solo en MIBE */}
                  {componentes?.HOME_BANNERS_TIENDAS_OFICIALES && (<TiendasBannersHome />)}
                  {/* tendencias */}
                  <TendenciasHome/>
                  {/* tiendas oficiales */}
                  {componentes?.HOME_TIENDAS_OFICIALES && (<TiendasBox />)}
                  {/* texto seo */}
                  {esMobile !== undefined && !esMobile && !esApp && (<TextoSeo />)}
                </>
              ) : (
                <div style={{ minhHeight: 1000 }}></div>
              )}
            </InView>
          </main>
          {/* footer */}
          {esMobile !== undefined && !esMobile && !esApp && (<Footer />)}
        </>
      )}

      {/* Imports que se carga pero no se muestran en la home */}
      <Modal />
      <PopUpAgregarALista />
      {!esApp && (
        <MenuFooterMobile />
      )}
    </>
  );
};

export default HomeLoader;
