import { faFire } from "@fortawesome/free-solid-svg-icons";
export var GLOBAL_CONFIG = {
    sliderTiendaOficial: {
        mediadefault: 2,
        media479: 2,
        media719: 2,
        media919: 3,
        media1140: 4,
        spaceBetween: 0,
        spaceBetweenDefault: 0,
        spaceBetween479: 0,
        spaceBetween719: 0,
        spaceBetween919: 0,
        spaceBetween1140: 0,
    },
    sliderNovedadesPorCateogria: {
        mediadefault: 2,
        media479: 2,
        media719: 3,
        media919: 2,
        media1140: 3,
        spaceBetween: 0,
        spaceBetweenDefault: 0,
        spaceBetween479: 0,
        spaceBetween719: 0,
        spaceBetween919: 0,
        spaceBetween1140: 0,
    },
    sliderNovedades: {
        mediadefault: 2,
        media479: 2,
        media719: 3,
        media919: 5,
        media1140: 6,
        spaceBetweenDefault: 0,
        spaceBetween479: 0,
        spaceBetween719: 0,
        spaceBetween919: 0,
        spaceBetween1140: 0,
    },
    sliderHotSale: {
        mediadefault: 6,
        media479: 2,
        media719: 3,
        media919: 5,
        media1140: 6,
    },
    textos: {
        tituloNovedadesEnTienda: "Novedades",
        tituloMasDeseadosEnTienda: "Los más deseados",
        tituloTodosLosArticulosEnTienda: "Descrubrí todo ",
        selectorDestinoDeEnvio: "Elegir destino",
        tituloColecciones: "Colecciones",
        tituloRecomendadosHome: "Recomendados solo para vos",
        tituloNovedesPorcategoriaHome: "¡Es nuevo y destacado!",
        textoInvitacionARegistro: " todas nuestras Ofertas Irresistibles.",
        botonInicioDeSesion: "Entrar a LOi",
        botonInicioDeSesionSubtitulo: "y accede a envío gratis",
        tituloSeccionTiendas: "Tiendas",
        descripcionSeccionTiendas: "Explorá entre una gran variedad de tiendas oficiales de marcas destacadas y descubrí increíbles productos y ofertas.",
        tituloTiendasPremium: "Tiendas Premium",
        tituloTiendasDestacadas: "Tiendas destacadas",
        tituloTiendasTodasLasTiendas: "Todas las tiendas",
        tituloHotSale: "Ofertas ahora en HotSale",
        tituloRastrearCompra: "Seguimiento de envíos",
        novedadesPageTitle: "Lo nuevo en LOi",
        novedadesPageDescription: "Descubre nuestras mas recientes Ofertas Irresistibles. Encuentra productos de alta calidad a precios inigualables en tecnología, moda, hogar y más. Ahorra con nuestras promociones diarias y disfruta de una experiencia de compra fácil y segura.",
        recomendadosPageTitle: "Recomendados solo para vos",
        recomendadosPageDescription: "Explora nuestras recomendaciones destacadas en LOi. Encuentra una cuidada selecci\u00F3n de productos recomendados especialmente para ti. Calidad y excelentes precios en cada recomendaci\u00F3n.",
        novedadesPorCategoriaPageTitle: "Novedades por categorias",
        novedadesPorCategoriaPageDescription: "Descubre las novedades en Ofertas Irresistibles en todo LOi. Todas las tendencias y productos a precios irresistibles con envío rápido a todo el país.",
        categoriasMostrarTodas: "Mostrar todas las categorías",
        categoriaListaTitulo: "Categorias de productos",
        categoriaListaTexto: "Descubre en LOi la mayor variedad de ofertas irresistibles con envío rápido y múltiples formas de pago.",
        losMasVendidosPageTitle: "Los más vendidos en LOi",
        losMasVendidosPageDescription: "Descubrí una selección exclusiva de los artículos más populares y demandados en LOi. Desde tecnología de última generación hasta accesorios imprescindibles, ropa de moda y productos para el hogar, te ofrecemos las mejores opciones al mejor precio. Mantente a la vanguardia con las tendencias más buscadas y disfruta de nuestras Ofertas Irresistibles.",
    },
    hotSale: {
        termina: "Termina en",
        textoHotSale: "Hot Sale",
        iconoHotSale: faFire,
        urlHotSale: "/hotsale",
    },
    iconoDeCarrito: "cart",
};
