var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from "react";
import Categoria from "../../../components/tarjetas/Categoria/Categoria";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { CategoriesContainer, ChildCategories, FatherCategory, ParentCategories, SonCategoryContainer, Subtitle, } from "./ListaDeCategoriasStyled";
import TituloSeccion from "../../../components/common/TituloSeccion";
var CategoryList = function (_a) {
    var categorias = _a.categorias;
    var _b = useState(), sonCategorySelected = _b[0], setSonCategorySelected = _b[1];
    useEffect(function () {
        setSonCategorySelected(categorias[0]);
    }, []);
    return (React.createElement(CategoriesContainer, null,
        React.createElement(ParentCategories, null, categorias.map(function (categoria, index) { return (React.createElement(FatherCategory, { key: categoria.id, className: sonCategorySelected &&
                sonCategorySelected.id === categoria.id &&
                "selected", onMouseEnter: function () { return setSonCategorySelected(categoria); }, onClick: function () { return setSonCategorySelected(categoria); }, tabIndex: index },
            React.createElement("span", null, categoria.nombre),
            React.createElement(FontAwesomeIcon, { icon: faChevronRight }))); })),
        sonCategorySelected && sonCategorySelected.hijos && (React.createElement(ChildCategories, { numberOfParentCategories: categorias.length },
            React.createElement(SonCategoryContainer, { key: sonCategorySelected.id, className: "fullWidth" },
                React.createElement(TituloSeccion, { titulo: sonCategorySelected.nombre, vinculo: sonCategorySelected.urlseo })),
            sonCategorySelected.hijos.map(function (sonCategory) { return (React.createElement(SonCategoryContainer, { key: sonCategory.id },
                React.createElement(Categoria, { categoria: sonCategory }))); }),
            sonCategorySelected.fabricantes.length >= 2 && (React.createElement(React.Fragment, null,
                React.createElement(SonCategoryContainer, { className: "fullWidth", key: "Todas las categorias" },
                    React.createElement(Subtitle, null,
                        "Todas las marcas en ",
                        sonCategorySelected.nombre)),
                sonCategorySelected.fabricantes.map(function (fabricante) { return (React.createElement(SonCategoryContainer, { key: fabricante.id, className: "smaller" },
                    React.createElement(Categoria, { categoria: __assign(__assign({}, fabricante), { urlseo: "".concat(sonCategorySelected.urlseo, "?filtro-marca=").concat(fabricante.nombre) }) }))); })))))));
};
export default CategoryList;
