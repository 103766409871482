import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import OpcionesUsuarioLogueado from "./OpcionesUsuarioLogueado";
/*#### icons ####*/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faUserCircle } from "@fortawesome/pro-light-svg-icons";

const MenuLoginHeader = ({ empresa, usuario }) => {
  const configuraciones = empresa && empresa.configuraciones;

  const textoInicioDeSession = useSelector(
    (state) =>
      state.empresa &&
      state.empresa.globalConfig &&
      state.empresa.globalConfig.textos &&
      state.empresa.globalConfig.textos.botonInicioDeSesion
  );



  const Logueado = () => {
    return (
      <InfoUserLogueado>
        <UserAvatar href="index.php?ctrl=usuarios">
          {usuario && usuario.datos && usuario.datos.usr_avatar === "" ? (
            <FontAwesomeIcon icon={faUserCircle} size="lg" />
          ) : (
            <img
              id="usuario-con-avatar"
              src={usuario && usuario.datos.usr_avatar}
              width="31"
              height="31"
            />
          )}
        </UserAvatar>
        <MensajeDeBienvenida>
          <div>Hola {usuario && usuario.datos.username}</div>
          <span>Tu cuenta y Listas</span>
        </MensajeDeBienvenida>
        <OpcionesUsuarioLogueado configuraciones={configuraciones} />
      </InfoUserLogueado>
    );
  };

  const NoLogueado = () => {
    return (
      <ContainerNoLogueado href="login">
        <FontAwesomeIcon icon={faUserCircle} size={"lg"} /> 
        <div>
        <header>{textoInicioDeSession}</header>
        </div>
      </ContainerNoLogueado>
    );
  };

  return !usuario ? (
    <IndicadorDeCarga spin icon={faSpinner} size="lg" />
  ) : (
    <>{usuario.logueado ? <Logueado /> : <NoLogueado />}</>
  );
};
const IndicadorDeCarga = styled(FontAwesomeIcon)`
  padding: 0 30px;
  color: ${(props) => props.theme.primarioHover};
`;
const MensajeDeBienvenida = styled.div`
  padding: 0 0 0 10px;
  font-size: 14px;
  text-decoration: none;
  white-space: nowrap;
  > div {
    display: inline-block;
    text-transform: capitalize;
    overflow: hidden;
    max-width: 15ch;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 14px;
  }
  > span {
    font-weight: bold;
    font-size: 10px;
    display: block;
  }
  @media screen and (max-width: 919px) {
    display: ${({ theme }) => theme.displayMensajeDeBienvenidaEnMobile};
  }
`;
const InfoUserLogueado = styled.div`
  position: relative;
  display: flex;
  padding: 5px;
  border-radius: ${(props) => props.theme.mediumBorderRadius};
  @media screen and (max-width: 719px) {
    display: none;
  }
  &:hover{
    background-color: ${(props) => props.theme.secundarioClaro};
  }
  &:hover > ul {
    display: block;
  }
`;

const UserAvatar = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    background-color: white;
    min-width: 35px;
    min-height: 35px;
    max-width: 35px;
    max-height: 35px;
    box-sizing: border-box;
    border-radius: ${({ theme }) => theme.totalBorderRadius};
    border: 1px solid ${(props) => props.theme.primario};
  }
`;

const ContainerNoLogueado = styled.a`
  @media screen and (max-width: 719px) {
    display: none;
  }
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: ${(props) => props.theme.buttonBorderRadius};
  text-decoration: none;
  white-space: nowrap;
  background-color: ${(props) => props.theme.fondoBotonLogin};
  border: thin solid ${(props) => props.theme.borderColorBotonLogin};
  color: ${(props) => props.theme.colorTextoBotonLogin};
  > div {
    margin-left: 5px;
    display: flex;
    flex-direction: column;
  }
  div > header{
    font-size: 14px;
    line-height: 14px;
  }
  div > span{
    font-size: 10px;
    line-height: 10px;
    font-weight: bold;
  }
  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.primarioHover};
    background-color: ${(props) => props.theme.secundarioClaro};
  }
`;

export default MenuLoginHeader;
