import { faBolt } from "@fortawesome/free-solid-svg-icons";
export var GLOBAL_CONFIG = {
    sliderTiendaOficial: {
        mediadefault: 2,
        media479: 2,
        media719: 2,
        media919: 3,
        media1140: 4,
        spaceBetween: 0,
        spaceBetweenDefault: 0,
        spaceBetween479: 0,
        spaceBetween719: 0,
        spaceBetween919: 0,
        spaceBetween1140: 0,
    },
    sliderNovedadesPorCateogria: {
        mediadefault: 2,
        media479: 2,
        media719: 3,
        media919: 4,
        media1140: 4,
        spaceBetween: 20,
        spaceBetweenDefault: 0,
        spaceBetween479: 0,
        spaceBetween719: 20,
        spaceBetween919: 30,
        spaceBetween1140: 40,
    },
    sliderNovedades: {
        mediadefault: 2,
        media479: 2,
        media719: 3,
        media919: 4,
        media1140: 5,
        spaceBetweenDefault: 0,
        spaceBetween479: 0,
        spaceBetween719: 20,
        spaceBetween919: 30,
        spaceBetween1140: 40,
    },
    sliderHotSale: {
        mediadefault: 5,
        media479: 2,
        media719: 3,
        media919: 4,
        media1140: 5,
    },
    textos: {
        tituloNovedadesEnTienda: "Novedades y tendencias",
        tituloMasDeseadosEnTienda: "Los más deseados",
        tituloTodosLosArticulosEnTienda: "Descrubrí mas de ",
        selectorDestinoDeEnvio: "Destino de envío",
        tituloColecciones: "Promociones",
        tituloRecomendadosHome: "Recomendados solo para vos",
        tituloNovedesPorcategoriaHome: "Destacados en MiBelleza",
        textoInvitacionARegistro: " la mejor selección de productos de belleza y cuidado personal.",
        botonInicioDeSesion: "Inicia sesión",
        botonInicioDeSesionSubtitulo: "y accede a envío gratis",
        tituloSeccionTiendas: "Marcas",
        descripcionSeccionTiendas: "Explorá nuestras exclusivas marcas y descubrí cientos en novedades en artículos de belleza y cuiddo personal.",
        tituloTiendasPremium: "Marcas Premium",
        tituloTiendasDestacadas: "Marcas destacadas",
        tituloTiendasTodasLasTiendas: "Todas las marcas",
        tituloHotSale: "Ofertas ahora en Flash Sale",
        tituloRastrearCompra: "Seguimiento de envíos",
        novedadesPageTitle: "Lo nuevo en MiBelleza",
        novedadesPageDescription: "Descubre \"Lo Nuevo en MiBelleza\", tu tienda de de belleza y cuidado personal de las mejores marcas del mundo. Encuentra las \u00FAltimas novedades en cosm\u00E9ticos, skincare y m\u00E1s. Disfruta de promociones exclusivas y compra con confianza.",
        recomendadosPageTitle: "Lo recomendado en MiBelleza",
        recomendadosPageDescription: "Explora nuestras recomendaciones destacadas en MiBelleza. Descubre una cuidadosa selecci\u00F3n de productos recomendados por expertos en cosm\u00E9ticos, cuidado de la piel y m\u00E1s, de las marcas l\u00EDderes a nivel mundial. Aprovecha nuestras sugerencias exclusivas y compra con confianza en nuestra tienda online.",
        novedadesPorCategoriaPageTitle: "Novedades por categorias",
        novedadesPorCategoriaPageDescription: "Descubre las novedades en productos de belleza y cuidado personal de las mejores marcas. Solo en MiBelleza.",
        categoriasMostrarTodas: "Mostrar todas las categorías",
        categoriaListaTitulo: "Todas las categorías",
        losMasVendidosPageTitle: "Los más vendidos de MiBelleza",
    },
    iconoDeCarrito: "bag",
    hotSale: {
        termina: "Termina en",
        textoHotSale: "Flash Sale",
        iconoHotSale: faBolt,
        urlHotSale: "/flashsale",
    },
    landings: {
        cabelloPro: true,
        marcasPremium: true,
        kerastasePremiere: true,
    },
};
