import { loiTheme } from "../themes/loi";
import { mibeTheme } from "../themes/mibelleza";

export default function themeHandler(location) {
  let THEME_ACTIVO = null;
  switch (location) {
    case "mibelleza.local":
    case "dev.mibelleza.uy":
    case "mibelleza.uy":
    case "mibelleza.com.uy":
      return THEME_ACTIVO = mibeTheme;
    case "loi.local":
    case "dev.loi.com.uy":
    case "loi.com.uy":
      return THEME_ACTIVO = loiTheme;
    default:
      return THEME_ACTIVO = loiTheme;
  }
}
