/**
 * Intrucciones de uso
 * 1- Importar este constantes en src/components/rastrearCompra/RastreoCompraHardCodeData.ts
 * 2- Comentar linea variable de datosEnvio y rastreoEnvio
 * 3- Ir al archivo src/routes/RastreaTuCompra.tsx cambiar 'step === 1' por false
 */
export var DatosEnvioHardCode = {
    TESTCASE00001: {
        vent_id: "TESTCASE00001",
        env_id: 4,
        retiroAgencia: true,
        envios: [
            {
                pedido_id: "0014011388",
                nombre: "TESTCASE00001",
                estado_id: 4,
                estado: "ENTREGADO",
                bultos: 1,
            },
        ],
    },
    TESTCASE00002: {
        vent_id: "TESTCASE00002",
        env_id: 4,
        retiroAgencia: true,
        envios: [
            {
                pedido_id: "0014011388",
                nombre: "TESTCASE00002",
                estado_id: 4,
                estado: "ENTREGADO",
                bultos: 1,
            },
        ],
    },
    TESTCASE00003: {
        vent_id: "TESTCASE00003",
        env_id: 4,
        retiroAgencia: true,
        envios: [
            {
                pedido_id: "0014011388",
                nombre: "TESTCASE00003",
                estado_id: 4,
                estado: "ENTREGADO",
                bultos: 1,
            },
        ],
    },
    TESTCASE00004: {
        vent_id: "TESTCASE00004",
        env_id: 4,
        retiroAgencia: true,
        envios: [
            {
                pedido_id: "0014011388",
                nombre: "TESTCASE00004",
                estado_id: 4,
                estado: "ENTREGADO",
                bultos: 1,
            },
        ],
    },
    TESTCASE00005: {
        vent_id: "TESTCASE00005",
        env_id: 4,
        retiroAgencia: true,
        envios: [
            {
                pedido_id: "0014011388",
                nombre: "TESTCASE00005",
                estado_id: 4,
                estado: "ENTREGADO",
                bultos: 1,
            },
        ],
    },
    TESTCASE00006: {
        vent_id: "TESTCASE00006",
        env_id: 4,
        retiroAgencia: true,
        envios: [
            {
                pedido_id: "0014011388",
                nombre: "TESTCASE00006",
                estado_id: 4,
                estado: "NOENTREGADO",
                bultos: 1,
            },
        ],
    },
};
export var RastreoEnvioHardCode = {
    TESTCASE00001: {
        info_rastreo: [
            {
                idPaquete: "0014011388",
                historialPaquete: [
                    {
                        id: "2963244",
                        pedido_id: "0014011388",
                        venta_id: "TESTCASE00001",
                        estado: "EN PROCESO DE PREPARACI\u00d3N",
                        extra: "",
                        estado_id: "1",
                        courier: "5",
                        create_at: "2/7/2024 08:05:04",
                        update_at: "2024-07-02 08:05:11",
                        nombre: "Preparando Env\u00edo",
                    },
                ],
            },
        ],
    },
    TESTCASE00002: {
        info_rastreo: [
            {
                idPaquete: "0014011388",
                historialPaquete: [
                    {
                        id: "2963244",
                        pedido_id: "0014011388",
                        venta_id: "TESTCASE00002",
                        estado: "EN PROCESO DE PREPARACI\u00d3N",
                        extra: "",
                        estado_id: "1",
                        courier: "5",
                        create_at: "2/7/2024 08:05:04",
                        update_at: "2024-07-02 08:05:11",
                        nombre: "Preparando Env\u00edo",
                    },
                    {
                        id: "2963473",
                        pedido_id: "0014011388",
                        venta_id: "TESTCASE00002",
                        estado: "TRANSITO",
                        extra: "Chofer: MARIA ACOSTA",
                        estado_id: "2",
                        courier: "5",
                        create_at: "2/7/2024 08:46:20",
                        update_at: "2024-07-02 09:01:04",
                        nombre: "En camino",
                    },
                ],
            },
        ],
    },
    TESTCASE00003: {
        info_rastreo: [
            {
                idPaquete: "0014011388",
                historialPaquete: [
                    {
                        id: "2963244",
                        pedido_id: "0014011388",
                        venta_id: "TESTCASE00003",
                        estado: "EN PROCESO DE PREPARACI\u00d3N",
                        extra: "",
                        estado_id: "1",
                        courier: "5",
                        create_at: "2/7/2024 08:05:04",
                        update_at: "2024-07-02 08:05:11",
                        nombre: "Preparando Env\u00edo",
                    },
                    {
                        id: "2963473",
                        pedido_id: "0014011388",
                        venta_id: "TESTCASE00003",
                        estado: "TRANSITO",
                        extra: "Chofer: MARIA ACOSTA",
                        estado_id: "2",
                        courier: "5",
                        create_at: "2/7/2024 08:46:20",
                        update_at: "2024-07-02 09:01:04",
                        nombre: "En camino",
                    },
                    {
                        id: "2963573",
                        pedido_id: "0014011388",
                        venta_id: "TESTCASE00003",
                        estado: "ENTREGADO",
                        extra: "Recibido Por: laura",
                        estado_id: "4",
                        courier: "5",
                        create_at: "2/7/2024 09:02:14",
                        update_at: "2024-07-02 09:15:03",
                        nombre: "Entregado",
                    },
                ],
            },
        ],
    },
    TESTCASE00004: {
        info_rastreo: [
            {
                idPaquete: "0014011388",
                historialPaquete: [
                    {
                        id: "2963244",
                        pedido_id: "0014011388",
                        venta_id: "TESTCASE00004",
                        estado: "EN PROCESO DE PREPARACI\u00d3N",
                        extra: "",
                        estado_id: "1",
                        courier: "5",
                        create_at: "2/7/2024 08:05:04",
                        update_at: "2024-07-02 08:05:11",
                        nombre: "Preparando Env\u00edo",
                    },
                    {
                        id: "2963473",
                        pedido_id: "0014011388",
                        venta_id: "TESTCASE00004",
                        estado: "TRANSITO",
                        extra: "Chofer: MARIA ACOSTA",
                        estado_id: "2",
                        courier: "5",
                        create_at: "2/7/2024 08:46:20",
                        update_at: "2024-07-02 09:01:04",
                        nombre: "En camino",
                    },
                    {
                        id: "2963473",
                        pedido_id: "0014011388",
                        venta_id: "TESTCASE00004",
                        estado: "TRANSITO",
                        extra: "Chofer: MARIA ACOSTA",
                        estado_id: "3",
                        courier: "5",
                        create_at: "2/7/2024 08:46:20",
                        update_at: "2024-07-02 09:01:04",
                        nombre: "Listo para retirar",
                    },
                    {
                        id: "2963473",
                        pedido_id: "0014011388",
                        venta_id: "TESTCASE00004",
                        estado: "TRANSITO",
                        extra: "Chofer: MARIA ACOSTA",
                        estado_id: "5",
                        courier: "5",
                        create_at: "2/7/2024 08:46:20",
                        update_at: "2024-07-02 09:01:04",
                        nombre: "Devuelto a Loi",
                    },
                    {
                        id: "2963473",
                        pedido_id: "0014011388",
                        venta_id: "TESTCASE00004",
                        estado: "TRANSITO",
                        extra: "Chofer: MARIA ACOSTA",
                        estado_id: "6",
                        courier: "5",
                        create_at: "2/7/2024 08:46:20",
                        update_at: "2024-07-02 09:01:04",
                        nombre: "Cancelado",
                    },
                    {
                        id: "2963473",
                        pedido_id: "0014011388",
                        venta_id: "TESTCASE00004",
                        estado: "TRANSITO",
                        extra: "Chofer: MARIA ACOSTA",
                        estado_id: "7",
                        courier: "5",
                        create_at: "2/7/2024 08:46:20",
                        update_at: "2024-07-02 09:01:04",
                        nombre: "Intento de entrega fallido",
                    },
                    {
                        id: "2963473",
                        pedido_id: "0014011388",
                        venta_id: "TESTCASE00004",
                        estado: "TRANSITO",
                        extra: "Chofer: MARIA ACOSTA",
                        estado_id: "8",
                        courier: "5",
                        create_at: "2/7/2024 08:46:20",
                        update_at: "2024-07-02 09:01:04",
                        nombre: "Recoordionado",
                    },
                ],
            },
        ],
    },
    TESTCASE00005: {
        info_rastreo: [
            {
                idPaquete: "0014011388",
                historialPaquete: [
                    {
                        id: "2963244",
                        pedido_id: "0014011388",
                        venta_id: "TESTCASE00005",
                        estado: "EN PROCESO DE PREPARACI\u00d3N",
                        extra: "",
                        estado_id: "1",
                        courier: "5",
                        create_at: "2/7/2024 08:05:04",
                        update_at: "2024-07-02 08:05:11",
                        nombre: "Preparando Env\u00edo",
                    },
                    {
                        id: "2963473",
                        pedido_id: "0014011388",
                        venta_id: "TESTCASE00005",
                        estado: "TRANSITO",
                        extra: "Chofer: MARIA ACOSTA",
                        estado_id: "2",
                        courier: "5",
                        create_at: "2/7/2024 08:46:20",
                        update_at: "2024-07-02 09:01:04",
                        nombre: "En camino",
                    },
                    {
                        id: "2963473",
                        pedido_id: "0014011388",
                        venta_id: "TESTCASE00005",
                        estado: "TRANSITO",
                        extra: "Chofer: MARIA ACOSTA",
                        estado_id: "7",
                        courier: "5",
                        create_at: "2/7/2024 08:46:20",
                        update_at: "2024-07-02 09:01:04",
                        nombre: "Intento de entrega fallido",
                    },
                    {
                        id: "2963573",
                        pedido_id: "0014011388",
                        venta_id: "TESTCASE00005",
                        estado: "ENTREGADO",
                        extra: "Recibido Por: laura",
                        estado_id: "4",
                        courier: "5",
                        create_at: "2/7/2024 09:02:14",
                        update_at: "2024-07-02 09:15:03",
                        nombre: "Entregado",
                    },
                ],
            },
        ],
    },
    TESTCASE00006: {
        info_rastreo: [
            {
                idPaquete: "0014011388",
                historialPaquete: [
                    {
                        id: "2963244",
                        pedido_id: "0014011388",
                        venta_id: "TESTCASE00006",
                        estado: "EN PROCESO DE PREPARACI\u00d3N",
                        extra: "",
                        estado_id: "1",
                        courier: "5",
                        create_at: "2/7/2024 08:05:04",
                        update_at: "2024-07-02 08:05:11",
                        nombre: "Preparando Env\u00edo",
                    },
                    {
                        id: "2963473",
                        pedido_id: "0014011388",
                        venta_id: "TESTCASE00006",
                        estado: "TRANSITO",
                        extra: "Chofer: MARIA ACOSTA",
                        estado_id: "3",
                        courier: "5",
                        create_at: "2/7/2024 08:46:20",
                        update_at: "2024-07-02 09:01:04",
                        nombre: "Listo para retirar",
                    },
                    {
                        id: "2963573",
                        pedido_id: "0014011388",
                        venta_id: "TESTCASE00006",
                        estado: "ENTREGADO",
                        extra: "Recibido Por: laura",
                        estado_id: "4",
                        courier: "5",
                        create_at: "2/7/2024 09:02:14",
                        update_at: "2024-07-02 09:15:03",
                        nombre: "Entregado",
                    },
                    {
                        id: "2963473",
                        pedido_id: "0014011388",
                        venta_id: "TESTCASE00006",
                        estado: "TRANSITO",
                        extra: "Chofer: MARIA ACOSTA",
                        estado_id: "2",
                        courier: "5",
                        create_at: "2/7/2024 08:46:20",
                        update_at: "2024-07-02 09:01:04",
                        nombre: "En camino",
                    },
                ],
            },
        ],
    },
};
