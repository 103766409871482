var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationPin } from "@fortawesome/pro-light-svg-icons";
import PopUpDestinoDeEnvio from "./PopUpDestinoDeEnvio";
//## redux data ##//
import { getDepartamentos } from "../redux/data/departamentos";
import { useAppDispatch, useAppSelector } from "../hooks/customReduxHooks";
var SelectorDireccionesHeader = function (_a) {
    var cargandoDesdeApp = _a.cargandoDesdeApp;
    var dispatch = useAppDispatch();
    var paisActivo = useAppSelector(function (state) { return state.empresa.empresaData && state.empresa.empresaData.pais_id; });
    var departamentos = useAppSelector(function (state) { return state.departamentos; }).departamentos;
    var usuario = useAppSelector(function (state) { return state.usuario; }).usuario;
    var _b = useState(false), visibilidadPopUpDestinoEnvio = _b[0], setVisibilidadPopUpDestinoEnvio = _b[1];
    var usuarioLogueado = usuario && usuario.logueado;
    var usuarioDireccion = usuario && usuario.datos && usuario.datos.direccion || null;
    var apiToken = process.env.API_TOKEN;
    var esApp = document.getElementById("esApp").value === "1";
    useEffect(function () {
        //dispatch(getUsuario())
        dispatch(getDepartamentos());
    }, []);
    /*variables con infomascion de localizacion
    traidas desde local storage */
    var departamentoIdLs = localStorage.getItem("departamentoId");
    var localidadIdLs = localStorage.getItem("localidadId");
    var localidadNombreLs = localStorage.getItem("localidadNombre");
    /* variable compuesta que devuelve true
    si todos los datos estan en local storage*/
    var existeDireccionEnLocalStorage = departamentoIdLs && localidadIdLs && localidadNombreLs ? true : false;
    /*funcion para guardar la localidad
    de en cookie */
    var guardarLocalidadEnCookie = function (localidadIdLs) {
        if (localidadIdLs) {
            //document.cookie = "localidadId=" + localidadIdLs;
            setCookie("localidadId", localidadIdLs);
        }
    };
    // Set a Cookie
    var setCookie = function (cName, cValue, expDays) {
        if (expDays === void 0) { expDays = 365; }
        var date = new Date();
        date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + date.toUTCString();
        document.cookie = cName + "=" + cValue + "; " + expires + ";domain=." + window.location.host + ";path=/";
    };
    useEffect(function () {
        /*si hay localidad en localstorage la guardo en sesion*/
        localidadIdLs && guardarLocalidadEnCookie(localidadIdLs);
    }, []);
    return (React.createElement(InfoEnviandoContainer, null,
        React.createElement(InfoEnviandoAWrapper, { onClick: function () { return setVisibilidadPopUpDestinoEnvio(true); } },
            !cargandoDesdeApp && React.createElement(FontAwesomeIcon, { icon: faLocationPin, size: 'lg' }),
            React.createElement(TextoEnviandoA, null,
                usuarioLogueado ? React.createElement(HeaderSelecctorDeDirecciones, { cargandoDesdeApp: cargandoDesdeApp }, "Enviando a:") :
                    (existeDireccionEnLocalStorage ? (React.createElement(HeaderSelecctorDeDirecciones, { cargandoDesdeApp: cargandoDesdeApp }, "Destino de env\u00EDo:")) : (React.createElement(HeaderSelecctorDeDirecciones, { cargandoDesdeApp: cargandoDesdeApp },
                        "Selecciona un ",
                        React.createElement("br", null),
                        "destino de env\u00EDo"))),
                React.createElement("article", null, usuarioLogueado && usuarioDireccion ? (usuarioDireccion.du_nombre) : existeDireccionEnLocalStorage ? (localStorage.getItem("localidadNombre")) : null))),
        cargandoDesdeApp && React.createElement(ButtonCambiarDestino, { onClick: function () { return setVisibilidadPopUpDestinoEnvio(true); } }, "Cambiar"),
        visibilidadPopUpDestinoEnvio && (React.createElement(PopUpDestinoDeEnvio, { paisActivo: paisActivo, setVisibilidadPopUpDestinoEnvio: setVisibilidadPopUpDestinoEnvio, usuarioLogueado: usuarioLogueado, usuarioDireccion: usuarioDireccion, departamentoIdLs: departamentoIdLs, departamentos: departamentos, localidadIdLs: localidadIdLs, guardarLocalidadEnCookie: guardarLocalidadEnCookie }))));
};
var ButtonCambiarDestino = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nmargin: 20px;\nfont-size: 16px;\nborder-radius: ", ";;\ncolor: white;\nborder: 0;\npadding: 10px 30px;\nbackground-color: ", ";\ncursor: pointer;\n&:hover{\n  background-color: ", ";\n}\n"], ["\nmargin: 20px;\nfont-size: 16px;\nborder-radius: ", ";;\ncolor: white;\nborder: 0;\npadding: 10px 30px;\nbackground-color: ", ";\ncursor: pointer;\n&:hover{\n  background-color: ", ";\n}\n"])), function (props) { return props.theme.totalBorderRadius; }, function (_a) {
    var theme = _a.theme;
    return theme.primarioHover;
}, function (_a) {
    var theme = _a.theme;
    return theme.primario;
});
var InfoEnviandoContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  @media screen and (max-width: ", ") {\n    position: ", ";\n    top: ", ";\n    z-index: 4;\n    background-color: ", ";\n    width: 100%;\n    margin: 0;\n    left: 0;\n    padding: ", ";\n    border-radius: ", ";\n    box-shadow: ", ";\n  }\n"], ["\n  @media screen and (max-width: ", ") {\n    position: ", ";\n    top: ", ";\n    z-index: 4;\n    background-color: ", ";\n    width: 100%;\n    margin: 0;\n    left: 0;\n    padding: ", ";\n    border-radius: ", ";\n    box-shadow: ", ";\n  }\n"])), function (props) { return props.theme.footerMobileVisibilityBreakPoint; }, function (props) { return props.theme.selectorDeDestinoPosicionMobile; }, function (props) { return props.theme.selectorDeDestinoTop; }, function (props) { return props.theme.selectorDeDestinoMobileBackground; }, function (props) { return props.theme.paddingSelectorDestinoDeEnvio; }, function (props) { return props.theme.selectorDeDestinoMobileBorderRadius; }, function (props) { return props.theme.selectorDeDestinoMobileBoxShadow; });
var TextoEnviandoA = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-left: 5px;\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  flex-direction: column;\n  > article {\n    white-space: nowrap;\n    overflow: hidden;\n    font-size: 14px;\n    text-overflow: ellipsis;\n    max-width: 10ch;\n    @media screen and (max-width: ", ") {\n      max-width: 16ch;\n      font-size: 16px;\n    }\n  }\n  @media screen and (max-width: ", ") {\n      flex-direction: row;\n      justify-content: flex-start;\n      align-items: center;\n  }\n"], ["\n  margin-left: 5px;\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  flex-direction: column;\n  > article {\n    white-space: nowrap;\n    overflow: hidden;\n    font-size: 14px;\n    text-overflow: ellipsis;\n    max-width: 10ch;\n    @media screen and (max-width: ", ") {\n      max-width: 16ch;\n      font-size: 16px;\n    }\n  }\n  @media screen and (max-width: ", ") {\n      flex-direction: row;\n      justify-content: flex-start;\n      align-items: center;\n  }\n"])), function (props) { return props.theme.footerMobileVisibilityBreakPoint; }, function (props) { return props.theme.footerMobileVisibilityBreakPoint; });
var HeaderSelecctorDeDirecciones = styled.header(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: ", ";\n  white-space: nowrap;\n  font-size: ", ";\n  line-height: ", ";\n  font-weight: bold;\n  @media screen and (max-width: ", ") {\n    position: relative;\n    margin-right: 5px;\n    font-size: 12px;\n    font-weight: normal;\n    top: unset;\n    left: unset;\n  }\n"], ["\n  display: ", ";\n  white-space: nowrap;\n  font-size: ", ";\n  line-height: ", ";\n  font-weight: bold;\n  @media screen and (max-width: ", ") {\n    position: relative;\n    margin-right: 5px;\n    font-size: 12px;\n    font-weight: normal;\n    top: unset;\n    left: unset;\n  }\n"])), function (props) { return props.theme.indicadorEnviandoA; }, function (props) { return props.cargandoDesdeApp ? "18px" : "10px"; }, function (props) { return props.cargandoDesdeApp ? "18px" : "12px"; }, function (props) { return props.theme.footerMobileVisibilityBreakPoint; });
var InfoEnviandoAWrapper = styled.section(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  border: thin solid transparent;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  border-radius: ", ";\n  padding:  ", ";\n  border: 0;\n  position: relative;\n  cursor: pointer;\n  padding: 10px;\n  >svg{\n    color: ", ";\n  }\n  &:hover{\n    background-color: ", ";\n  }\n\n  @media screen and (max-width: ", ") {\n    margin: 0;\n    border: 0;\n    flex-direction: row;\n    display: ", ";\n    align-items: center;\n    justify-content: center;\n  }\n  article {\n    white-space: nowrap;\n    min-width: auto;\n  }\n"], ["\n  border: thin solid transparent;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  border-radius: ", ";\n  padding:  ", ";\n  border: 0;\n  position: relative;\n  cursor: pointer;\n  padding: 10px;\n  >svg{\n    color: ", ";\n  }\n  &:hover{\n    background-color: ", ";\n  }\n\n  @media screen and (max-width: ", ") {\n    margin: 0;\n    border: 0;\n    flex-direction: row;\n    display: ", ";\n    align-items: center;\n    justify-content: center;\n  }\n  article {\n    white-space: nowrap;\n    min-width: auto;\n  }\n"])), function (props) { return props.theme.mediumBorderRadius; }, function (props) { return props.theme.paddingSelectorDestinoDeEnvio; }, function (props) { return props.theme.primario; }, function (_a) {
    var theme = _a.theme;
    return theme.secundarioClaro;
}, function (props) { return props.theme.footerMobileVisibilityBreakPoint; }, function (props) { return props.theme.selectorDeDestinoMobileDisplayMode; });
export default SelectorDireccionesHeader;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
