var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Empresa from "../services/empresa";
import EnviosDisponibles from "../services/enviosDisponibles";
import { GLOBAL_CONFIG as globalConfigMibe } from "../../constants/mibe";
import { GLOBAL_CONFIG as globalConfigLoi } from "../../constants/loi";
var empresaId = document.getElementById("emp_id").value;
var initialState = {
    empresaData: null,
    enviosDisponibles: null,
    textoSeo: null,
    anuncios: null,
    globalConfig: empresaId === "4" ? globalConfigMibe : globalConfigLoi,
};
var empresaSlice = createSlice({
    name: "empresa",
    initialState: initialState,
    reducers: {
        resetEmpresa: function (state) { return initialState; },
    },
    extraReducers: function (builder) {
        builder.addCase(getEmpresa.fulfilled, function (state, action) {
            state.empresaData = action.payload;
        });
        builder.addCase(getEnviosDisponibles.fulfilled, function (state, action) {
            state.enviosDisponibles = action.payload;
        });
        builder.addCase(getTextoSeo.fulfilled, function (state, action) {
            state.textoSeo = action.payload;
        });
        builder.addCase(getAnuncios.fulfilled, function (state, action) {
            state.anuncios = action.payload;
        });
    },
});
export var getEmpresa = createAsyncThunk("getEmpresa/index", function () { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_1, error_empresa;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Empresa.getEmpresa()];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                err_1 = _a.sent();
                error_empresa = "ERROR_GET_EMPRESA";
                console.error(error_empresa, err_1);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var getTextoSeo = createAsyncThunk("getTextoSeo", function () { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_2, error_texto_seo;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Empresa.getTextoSeo()];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                err_2 = _a.sent();
                error_texto_seo = "ERROR_GET_TEXTO_SEO";
                console.error(error_texto_seo, err_2);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var getEnviosDisponibles = createAsyncThunk("getEnviosDisponibles", function () { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_3, error_envios_disponibles;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, EnviosDisponibles.getEnviosDisponibles()];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                err_3 = _a.sent();
                error_envios_disponibles = "ERROR_GET_ENVIOS_DISPONIBLES";
                console.error(error_envios_disponibles, err_3);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var getAnuncios = createAsyncThunk("getAvisos", function () { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_4, error_anuncios;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Empresa.getAnuncios()];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                err_4 = _a.sent();
                error_anuncios = "ERROR_GET_ANUNCIOS";
                console.error(error_anuncios, err_4);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export default empresaSlice;
