import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Localidades from "../services/localidades";

export const getLocalidades = createAsyncThunk("getLocalidades/index", async(id)=>{
    try{
        const response = await Localidades.getLocalidades(id);
        return response
    }catch(err){
        const error_localidades = "ERROR_GET_LOCALIDADES";
        console.error(error_localidades, err)
    }
});
const initialState = {
    localidades:null
};
const localidadesSlice = createSlice({
    name:"localidades",
    initialState,
    reducers: {
        resetLocalidades:(state) => initialState,
    },
    extraReducers: {
        [getLocalidades.fulfilled]:(state, action) => {
            state.localidades = action.payload;
        }
    }
});
export default localidadesSlice;