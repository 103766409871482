const apiToken = process.env.API_TOKEN;
const Departamentos = {
    getDepartamentos: async() => {
        try{
            const response = await fetch("/index.php?ctrl=index&act=obtenerDepartametos", {
                method: "GET",
                headers: { Authorization: "Bearer " + apiToken },
            });
            const responseDepartamentos = response.json();
            return responseDepartamentos;
        }catch(err){
            const error = "ERROR_GET_DEPARTAMENTOS_SERVICE";
            return error;
        }
    }
};
export default Departamentos;