import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Favoritos from "../services/favoritos";

//agregar o eliminar favoritos
export const obtenerFavoritosUsuario = createAsyncThunk(
  "obtenerFavoritosUsuario/index",
  async (data) => {
    try {
      const response = await Favoritos.obtenerFavoritosUsuario(data);
      return response;
    } catch (err) {
      const error_agregar_eliminar_favorito = "ERROR_OBTENER_FAVORITO";
      console.error(error_agregar_eliminar_favorito, err);
    }
  }
);

const initialState = {
  favorito: null,
};
//slice
const favoritosSlice = createSlice({
    name: "favoritos",
    initialState,
    reducers: {
        resetFavoritos: (state) => initialState,       
    },
    extraReducers: {
        //agregar eliminar favorito   
      [obtenerFavoritosUsuario.fulfilled]: (state, action) => {
        state.favorito = action.payload;
      },     
      //end agregar eliminar favorito
    },
});
export default favoritosSlice;
