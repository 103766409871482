const Notificaciones = {
  //##fetch cantidad de notificaciones ##//
  fetchCantidadDeNotificaciones: async (auth) => {
    try {
      const response = await fetch(
        "/index.php?ctrl=notificaciones&act=obtenerCantidadNotificaciones",
        {
          method: "GET",
          headers: {
            Usuarioid: auth.Usuarioid,
            Usuariotoken: auth.Usuariotoken,
            Authorization: auth.Authorization,
          },
        }
      );
      const cantidadDeNotificacionesData = await response.json();
      return cantidadDeNotificacionesData;
    } catch (err) {
      const error = "ERROR_SERVICE_CANTIDAD_DE_NOTIFICACIONES";
      console.log(error);
    }
  },
  //## fetch todas las notificaciones sin leer ##//
  fetchTodasLasNotificacionesSinLeer: async (auth) => {
    try {
      const response = await fetch(
        "/index.php?ctrl=notificaciones&act=obtenerNotificaciones&sinLeer=1",
        {
          method: "GET",
          headers: {
            Usuarioid: auth.Usuarioid,
            Usuariotoken: auth.Usuariotoken,
            Authorization: auth.Authorization,
          },
        }
      );
      const todasLasNotificacionesSinLeer = await response.json();
      return todasLasNotificacionesSinLeer;
    } catch (err) {
      const error = "ERROR_SERVICE_TODAS_LAS_NOTIFICACIONES_SIN_LEER";
      console.log(error);
    }
  },
  //## fetch todas las notificaciones ##//
  fetchTodasLasNotificaciones: async (auth) => {
    try {
      const response = await fetch(
        "/index.php?ctrl=notificaciones&act=obtenerNotificaciones",
        {
          method: "GET",
          headers: {
            Usuarioid: auth.Usuarioid,
            Usuariotoken: auth.Usuariotoken,
            Authorization: auth.Authorization,
          },
        }
      );
      const todasLasNotificaciones = await response.json();
      return todasLasNotificaciones;
    } catch (err) {
      const error = "ERROR_SERVICE_TODAS_LAS_NOTIFICACIONES";
      console.log(error);
    }
  },
  //## patch notificacion ##//

  patchNotificacion: async (data) => {
    const response = fetch(
      `/index.php?ctrl=notificaciones&act=${data.accion}`,
      {
        method: "PATCH",
        headers: data.headersAuth,
        body: data.id,
      });
      return response;
    try {
    } catch (err) {
      const error = "ERROR_PATCH_NOTIFICACION";
      console.log(error);
    }
  },
};
export default Notificaciones;
