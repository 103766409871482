import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/pro-light-svg-icons";
import styled from "styled-components";
//## redux data ##//
import { getUsuario } from '../redux/data/usuario';

const FavoritosHeader = () => {
  //const dispatch = useDispatch();
  const logueado = useSelector((state) => state.usuario && state.usuario.usuario && state.usuario.usuario.logueado)

  useEffect(() => {
    //dispatch(getUsuario())
  },[])
  

  return (
    <>
      {logueado ? (
        <Favoritos href="perfil/listas"  aria-label="Ver tus favoritos">
          <FontAwesomeIcon icon={faHeart} size={'lg'} />
          <span>Ver tus favoritos</span>
        </Favoritos>
      ) : (
        <Favoritos href="login" aria-label="Inicia sesión para ver tus favoritos">
          <FontAwesomeIcon icon={faHeart} size={'lg'}  />
          <span>Inicia sesión para ver tus favoritos</span>
        </Favoritos>
      )}
    </>
  );
};
const Favoritos = styled.a`
  display: flex;
  text-decoration: none;
  padding: 10px;
  color: ${(props) => props.theme.primario};
  border-radius: ${(props) => props.theme.mediumBorderRadius};
  > span{
    display: none ;
  }
  &:hover {
    color: ${(props) => props.theme.primarioHover};
    background-color: ${(props) => props.theme.secundarioClaro};
  }
  @media screen and (max-width: 719px) {
    display: none;
  }
`;
export default FavoritosHeader;
