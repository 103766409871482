import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";

const CategoriasSegundoNivel = ({ categoria }) => {
  const [categoriaDesplegada, setCategoriaDesplegada] = useState(false);

  useEffect(() => {
    window.innerWidth >= 919 && setCategoriaDesplegada(true);
    window.innerWidth <= 919 && setCategoriaDesplegada(false);
    window.addEventListener("resize",() => window.innerWidth >= 919 && setCategoriaDesplegada(true));
  },[]);


  return (
    <Categoria key={categoria.id}>
      <a href={categoria.urlseo}>
        {categoria.nombre}
        {!categoriaDesplegada && (
          <button
            onClick={(e) => {
              e.preventDefault();
              setCategoriaDesplegada(true);
            }}
          ><FontAwesomeIcon icon={faChevronDown} size={"2x"} />
          </button>
        )}
        {categoriaDesplegada && (
          <button
            onClick={(e) => {
              e.preventDefault();
              setCategoriaDesplegada(false);
            }}
          ><FontAwesomeIcon icon={faChevronUp} size={"2x"} />
          </button>
        )}
      </a>
      {categoriaDesplegada &&
        categoria &&
        categoria.hijos &&
        categoria.hijos.length > 0 && (
          <ul>
            {categoria.hijos.map((nieta, indexNieta) => (
              <li key={indexNieta}>
                <a href={nieta.urlseo}>{nieta.nombre}</a>
              </li>
            ))}
          </ul>
        )}
    </Categoria>
  );
};

const Categoria = styled.li`
  width: 100%;
  margin: 0 0 5px 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  break-inside: avoid-column;
  > a {
    font-weight: bold;
    font-size: 16px;
    color: black;
    border-bottom: 0;
    padding: 0;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover {
      text-decoration: none;
    }
    > button {
      font-size: 10px;
      color: ${(props) => props.theme.primario};
      background-color: ${(props) => props.theme.secundarioClaro};
      border: 0;
      margin: 0;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        cursor: pointer;
        background-color: ${(props) => props.theme.secundarioClaro};
        color: ${(props) => props.theme.primarioHover};
      }
      @media screen and (min-width: ${(props) => props.theme.footerMobileVisibilityBreakPoint}) {
        display: none;
      }
    }
    @media screen and (max-width: ${(props) => props.theme.footerMobileVisibilityBreakPoint}) {
      font-weight: 400;
      padding: 0;
    }
  }
  /** abuelas */
  > ul {
    margin-bottom: 20px;
    > li {
      > a {
        font-weight: normal;
        font-size: 14px;
        padding: 5px 0;
        display: block;
        color: black;
        text-decoration: none;
        > svg{
          display: none;
        }
        &:hover {
          text-decoration: none;
          text-decoration: underline;
        }
        @media screen and (max-width: ${(props) => props.theme.footerMobileVisibilityBreakPoint}) {
          padding: 10px;
          background-color: ${(props) => props.theme.secundarioClaro};
          > svg{
          display: inline-block;
          }
        }
      }
    }
  }
`;

export default CategoriasSegundoNivel;
