import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  agregarRestarProductoCarrito,
  getCarritoHeader,
} from "../redux/data/carrito";

const ComunidadorCantidadCarrito = () => {
  const dispatch = useDispatch();
  const { productoEnCarrito } = useSelector((state) => state.carrito);

  let timeout = [];
  let idProd = "";
  useEffect(() => {
    if (productoEnCarrito) {
      if (idProd != productoEnCarrito.id) {
        clearTimeout(timeout[idProd]);
      }
      idProd = productoEnCarrito.id;
      timeout[idProd] = setTimeout(() => {
        timeout[idProd] = null;
        productoEnCarrito &&
          dispatch(agregarRestarProductoCarrito(productoEnCarrito));
      }, 1000);
      return () => {
        clearTimeout(timeout[idProd]);
      };
    }
  }, [productoEnCarrito]);

  const esHome = document.getElementById("esHome").value === "1";

  useEffect(() => {
    if (!esHome) {
      let cantidadEnDom = document.getElementById("cantidadEnCarrito");
      cantidadEnDom.addEventListener("change", () => {
        dispatch(getCarritoHeader());
      });
    }
  }, []);

  return null;
};

export default ComunidadorCantidadCarrito;
