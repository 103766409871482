import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faEnvelope,
  faMobile,
  faSms,
  faRobot,
  faTrash
} from "@fortawesome/pro-light-svg-icons";

const procesarIconoDeNotificacion = (tipoNotificaciones) => {
  let notificationIcon = faBell;
  if (tipoNotificaciones.length > 1) {
    notificationIcon = faBell;
  } else if (tipoNotificaciones.includes("mail")) {
    notificationIcon = faEnvelope;
  } else if (tipoNotificaciones.includes("sms")) {
    notificationIcon = faSms;
  } else if (tipoNotificaciones.includes("push")) {
    notificationIcon = faMobile;
  } else if (tipoNotificaciones.includes("internal")) {
    notificationIcon = faRobot;
  }
  return <FontAwesomeIcon icon={notificationIcon} />;
};

const Notificacion = ({notificacion, patchearNotificacion, uiContext}) => {
  
  return (
    <NotificacionBody>
      <div className="indicador-tipo-notificacion">
        {notificacion.leido ? null : <div className="indicador-no-leido"></div>}
        {procesarIconoDeNotificacion(notificacion.tipoNotificaciones)}
      </div>
      <div className="info-notificacion" onClick={() =>
            patchearNotificacion(uiContext, "marcarNotificacionesLeidas", [notificacion.id])
          }>
        <a href={notificacion.url}>
          { uiContext === 'header' ? null : <p>{notificacion.created_at}</p>}
          <h4>{notificacion.titulo}</h4>
          <p>{notificacion.descripcion}</p>
        </a>
      </div>
      <div className="opciones-notificacion">
        <button
          onClick={() =>
            patchearNotificacion(uiContext, "borrarNotificaciones", [notificacion.id])
          }
        >
          <FontAwesomeIcon size={"lg"} icon={faTrash} />
        </button>
      </div>
    </NotificacionBody>
  );
};
const NotificacionBody = styled.li`

        padding: 10px;
        display: flex;
        &:hover {
          button {
            display: block;
          }
        }
        button {
          display: none;
        }
        div.indicador-tipo-notificacion {
          position: relative;
          > svg {
            margin: 10px 10px 0 5px;
          }
          div.indicador-no-leido {
            width: 10px;
            height: 10px;
            background-color: red;
            border-radius: 10px;
            position: absolute;
            top: 5px;
            left: 0;
          }
        }
        > div.info-notificacion {
          > a {
            text-decoration: none;
            color: inherit;
            > h4 {
              font-size: 14px;
              margin: 0;
              padding: 0;
              color: #014ECB;
            }
            > p {
              font-size: 12px;
              margin: 0;
              padding: 0;
              color: black;
            }
          }
          > p.notificacion-time {
            color: gray;
            font-size: 10px;
          }
        }
        div.opciones-notificacion {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: auto;
          min-width: 30px;
          > button {
            margin: 0;
            padding: 5px;
            border: 0;
            background-color: transparent;
            > svg.fa-trash {
              color: gray;
            }
            &:hover {
              > svg.fa-trash {
                color: #014ECB;
              }
            }
          }
        }
`;

export default Notificacion;
