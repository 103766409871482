import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBox } from "@fortawesome/pro-light-svg-icons";

const IconoRastreoHeader = () => {
  return (
    <StyledIconoRastreoHeader href="/rastrea-tu-compra">
      <FontAwesomeIcon icon={faBox} size="lg" />
      <span>Rastrea tu<br/>compra</span>
    </StyledIconoRastreoHeader>
  );
};

const StyledIconoRastreoHeader = styled.a`
color: black;
display: flex;
align-items: center;
justify-content: center;
text-decoration: none;
padding: 10px;
border-radius: ${(props) => props.theme.mediumBorderRadius};
> svg{
  color: ${(props) => props.theme.primario};
}
> span{
  white-space: nowrap;
  margin-left: 10px;
  font-size: 10px;
  line-height: 12px;
  font-weight: bold;
  @media only screen and (max-width: 919px) {
    display: none;
  }
}
&:hover {
    background-color: ${(props) => props.theme.secundarioClaro};
}
`;

export default IconoRastreoHeader;
